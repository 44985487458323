import styled from 'styled-components'
import { BareButton } from './Button'
import Icon from '../Icon'

export const TableActionIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`

export const TableAction = styled(BareButton)`
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`

export const TableActions = styled.div`
  display: flex;
  justify-content: center;
`

export const TableCell = styled.td`
  padding: 16px 14px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
`

export const TableRow = styled.tr`
  background-color: ${props => props.backgroundColor};
`

export const TableBody = styled.tbody`
  background-color: #fff;
`

export const TableHead = styled.thead`
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 15px;

  ${TableRow}:last-child {
    background-color: ${props => props.theme.brandPrimary};
    color: #fff;

    ${TableCell} {
      padding: 8px 14px;
    }
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TablePaginationButtonIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`

export const TablePaginationButton = styled(BareButton)`
  margin-right: 4px;
  padding: 6px 8px;
  border-radius: 6px;

  &:hover {
    background-color: ${props => props.theme.grayLight};
  }
`

export const TablePaginationSelect = styled.select`
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
  border: 1px solid ${props => props.theme.gray};
  outline: none;
`

export const TablePaginationInput = styled.input`
  width: 40px;
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
  border: 1px solid ${props => props.theme.gray};
  outline: none;
`

export const TablePaginationSection = styled.div`
  display: flex;
  align-items: center;
`

export const TablePagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 14px 8px 6px;
  background-color: ${props => props.theme.grayLighter};
`
