import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { changeAccountPassword, resetAccount } from '../../ducks'
import { createSuccessNotification } from '../../../core/ducks'
import { Message } from '../../../core/components/styled'
import { DocumentTitle, Page } from '../../../common/components'
import { changePasswordSchema } from '../../schemas'
import { AccountPanel, AccountPanelTitle } from '../styled'
import { ChangePasswordForm } from '../forms'

const ChangePasswordPage = ({
  isChangePassword,
  isChangingPassword,
  isChangePasswordFailed,
  createSuccessNotification,
  changeAccountPassword,
  resetAccount,
}) => {
  const handleSubmit = useCallback(
    data => {
      changeAccountPassword(data)
        .then(() =>
          createSuccessNotification(
            'Your password was successfully changed.',
            false,
          ),
        )
        .catch(() => {})
    },
    [createSuccessNotification, changeAccountPassword],
  )

  useEffect(() => resetAccount, [resetAccount])

  return (
    <>
      <DocumentTitle>Change your password</DocumentTitle>
      <Page>
        <AccountPanel isLoading={isChangingPassword}>
          <AccountPanelTitle>Change your password</AccountPanelTitle>

          <Formik
            render={ChangePasswordForm}
            initialValues={{
              currentPassword: '',
              password: '',
              confirmedPassword: '',
            }}
            validationSchema={changePasswordSchema}
            onSubmit={handleSubmit}
          />

          {isChangePasswordFailed && (
            <Message animated margin="medium no no" color="alert">
              Could not change your password.
            </Message>
          )}

          {isChangePassword && <Redirect to={{ pathname: '/' }} />}
        </AccountPanel>
      </Page>
    </>
  )
}

ChangePasswordPage.propTypes = {
  isChangePassword: PropTypes.bool.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  isChangePasswordFailed: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  changeAccountPassword: PropTypes.func.isRequired,
  resetAccount: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isChangePassword: state.account.account.isChangePassword,
  isChangingPassword: state.account.account.isChangingPassword,
  isChangePasswordFailed: state.account.account.isChangePasswordFailed,
})

const mapDispatchToProps = {
  createSuccessNotification,
  changeAccountPassword,
  resetAccount,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordPage)
