import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router'
import { isAdmin } from '../../ducks'

const AdminRoute = ({ isAdmin, component: Component, ...rest }) => {
  const renderComponent = props => {
    if (isAdmin) return <Component {...props} />
    return <Redirect to={{ pathname: '/' }} />
  }

  return <Route {...rest} render={renderComponent} />
}

AdminRoute.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state.account.login.user),
})

export default withRouter(connect(mapStateToProps)(AdminRoute))
