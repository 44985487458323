import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Formik } from 'formik'
import {
  createSuccessNotification,
  createErrorNotification,
} from '../../../core/ducks'
import {
  PageContent,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { EMPTY_PROVIDER } from '../../constants'
import { saveProvider } from '../../ducks'
import { providerSchema } from '../../schemas'
import { ProviderEditorForm } from '../forms'

const ProviderCreatorPage = ({
  isSavingProvider,
  createSuccessNotification,
  createErrorNotification,
  saveProvider,
  push,
}) => {
  const handleProviderEditorFormSubmit = useCallback(
    async (formData, { resetForm }) => {
      try {
        resetForm(formData)
        const providerId = await saveProvider(undefined, formData)
        createSuccessNotification('The provider details were saved.')
        push(`/providers/${providerId}`)
      } catch (error) {
        createErrorNotification('Saving the provider failed.')
      }
    },
    [saveProvider, createSuccessNotification, createErrorNotification, push],
  )

  const renderProviderEditorForm = useCallback(
    formikProps => (
      <ProviderEditorForm
        isAdmin
        isFullyManagedByAdmin
        isNewProvider
        isSavingProvider={isSavingProvider}
        {...formikProps}
      />
    ),
    [isSavingProvider],
  )

  return (
    <PageContent compact>
      <PageHeader>
        <PageTitle>Create new provider</PageTitle>
      </PageHeader>

      <Formik
        render={renderProviderEditorForm}
        initialValues={EMPTY_PROVIDER}
        validationSchema={providerSchema}
        onSubmit={handleProviderEditorFormSubmit}
      />
    </PageContent>
  )
}

ProviderCreatorPage.propTypes = {
  isSavingProvider: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  saveProvider: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isSavingProvider: state.providers.provider.isSaving,
})

const mapDispatchToProps = {
  createSuccessNotification,
  createErrorNotification,
  saveProvider,
  push,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderCreatorPage)
