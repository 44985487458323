import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { PRODUCTION } from './core/constants'
import { getEnvironment } from './core/services/environment'
import { reducer as coreReducer } from './core/ducks'
import { reducer as accountReducer } from './account/ducks'
import { reducer as providersReducer } from './providers/ducks'
import { reducer as moderationReducer } from './moderation/ducks'
import { history } from './core/services'

const routerReducer = connectRouter(history)

const composeEnhancers =
  getEnvironment() !== PRODUCTION
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose

const rootReducer = combineReducers({
  router: routerReducer,
  core: coreReducer,
  account: accountReducer,
  providers: providersReducer,
  moderation: moderationReducer,
})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))),
)

export default store
