import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { loadingOverlay } from '../../../core/styles'

export const AccountPanelTitle = styled.h1`
  margin: 0 0 35px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 24px;
`

export const AccountPanelInfo = styled.div`
  width: 100%;
  margin: 10px 0;
  text-align: left;
  line-height: 18px;
  font-size: 13px;
  color: ${props => props.theme.brandPrimary};
`

export const AccountPanel = styled.div`
  position: relative;
  width: 480px;
  margin: 20px auto;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 8px ${props => transparentize(0.9, props.theme.grayBase)};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `}
`
