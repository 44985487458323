import React from 'react'
import PropTypes from 'prop-types'
import {
  Radio as RadioContainer,
  RadioInput,
  RadioCheck,
  RadioText,
} from './styled'

const Radio = ({ field, label, disabled, block, size, margin }) => (
  <RadioContainer
    isDisabled={disabled}
    block={block}
    size={size}
    margin={margin}
  >
    <RadioInput {...field} type="radio" disabled={disabled} />
    <RadioCheck />
    <RadioText>{label}</RadioText>
  </RadioContainer>
)

Radio.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  margin: PropTypes.string,
}

Radio.defaultProps = {
  disabled: undefined,
  block: false,
  size: undefined,
  margin: undefined,
}

export default Radio
