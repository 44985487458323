import React from 'react'
import PropTypes from 'prop-types'
import { ButtonSet, Button, Grid } from '../../../core/components/styled'
import { ButtonLink } from '../../../core/components'
import { AccountEditorFields } from '../sections'

const AccountEditorForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Grid multiLine>
      <AccountEditorFields />
    </Grid>

    <ButtonSet>
      <Button fluid color="primary" margin="no no xSmall" type="submit">
        Update
      </Button>

      <Button type="button" as={ButtonLink} to="/" fluid line color="primary">
        Cancel
      </Button>
    </ButtonSet>
  </form>
)

AccountEditorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default AccountEditorForm
