import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { GlobalStyle } from './core/components/styled'
import { theme } from './core/styles'
import { NotificationSet } from './core/components'
import { Page } from './common/components'
import { AccountRouter } from './account/components'
import { AuthRoute } from './account/components/routes'
import { registerAuthInterceptor } from './account/services'
import { ProvidersRouter } from './providers/components'
import { ModerationRouter } from './moderation/components'
import { history } from './core/services'
import initialize from './core/services/initialize'
import store from './store'

initialize()
registerAuthInterceptor()

const AppRouter = () => (
  <Page>
    <AuthRoute path="/providers" component={ProvidersRouter} />
    <AuthRoute path="/moderation/claims" component={ModerationRouter} />
    <AuthRoute path="/moderation/publications" component={ModerationRouter} />
  </Page>
)

render(
  <ThemeProvider theme={theme}>
    <>
      <Normalize />
      <GlobalStyle />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/account" component={AccountRouter} />
            <Redirect exact from="/" to="/providers" />
            <AuthRoute component={AppRouter} />
          </Switch>
        </ConnectedRouter>
        <NotificationSet />
      </Provider>
    </>
  </ThemeProvider>,
  document.getElementById('root'),
)
