import { http } from '../../core/services'

export const loadAmenities = providerId =>
  http
    .get(`management/providers/${providerId}/amenities`)
    .then(response => response.data)

export const updateAmenities = (providerId, amenityIds) =>
  http
    .put(`management/providers/${providerId}/amenities`, amenityIds)
    .then(response => ({ checksum: response.headers['x-content-checksum'] }))
