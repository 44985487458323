const EMPTY_PROVIDER = {
  name: '',
  facilityCode: '',
  categories: [],
  state: '',
  county: '',
  city: '',
  address: '',
  zipCode: '',
  latitude: '',
  longitude: '',
  licensee: '',
  licenseCode: '',
  licenseEffective: '',
  licenseExpiration: '',
  capacity: '',
  supplementaryServicesProvided: '',
  isFeatured: false,
  phoneNumber: '',
  faxNumber: '',
  isMedicaidCertified: false,
  description: '',
}

export default EMPTY_PROVIDER
