import moment from 'moment'
import Cookie from 'js-cookie'
import { AUTH_TOKEN_EXPIRATION_TIME } from '../constants'

export const setSessionValues = ({ user, accessToken }) => {
  const accessTokenExpires = moment()
    .add(AUTH_TOKEN_EXPIRATION_TIME, 'seconds')
    .toDate()

  if (user) Cookie.set('user', user, { expires: accessTokenExpires })
  if (accessToken)
    Cookie.set('accessToken', accessToken, { expires: accessTokenExpires })
}

export const createSession = (user, accessToken) =>
  setSessionValues({
    user,
    accessToken,
  })

export const getSessionValue = name => Cookie.getJSON(name)

export const destroySession = () => {
  Cookie.remove('user')
  Cookie.remove('accessToken')
}
