import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import {
  createSuccessNotification,
  createErrorNotification,
} from '../../../core/ducks'
import { PageLoading } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { verifyAccount, resetAccount } from '../../ducks'
import { getProviderClaimUrl } from '../../services'

const VerificationPage = ({
  createSuccessNotification,
  createErrorNotification,
  verifyAccount,
  resetAccount,
  push,
  location,
}) => {
  const redirectUrl = getProviderClaimUrl() || '/providers'
  verifyAccount(location.search)
    .then(() => {
      createSuccessNotification(
        'Your account email was verified. Please login to manage your communities.',
      )
      push(redirectUrl)
    })
    .catch(() => {
      createErrorNotification(
        'Email verification failed! Please make sure that you have copied and pasted the entire URL from your email in your browser. If you are still having trouble verifying your email support@lovedonesfirst.com.',
      )
      push('/providers')
    })

  useEffect(() => resetAccount, [resetAccount])

  return (
    <>
      <DocumentTitle>Verifying your account email ...</DocumentTitle>
      <PageLoading />
    </>
  )
}

VerificationPage.propTypes = {
  createSuccessNotification: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  verifyAccount: PropTypes.func.isRequired,
  resetAccount: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
}

const mapDispatchToProps = {
  createSuccessNotification,
  createErrorNotification,
  verifyAccount,
  resetAccount,
  push,
}

export default connect(
  undefined,
  mapDispatchToProps,
)(VerificationPage)
