const getStatuses = amenities =>
  amenities.reduce((statuses, amenityGroup) => {
    amenityGroup.amenities.forEach(amenity => {
      statuses[amenity.id] = amenity.assigned
    })

    return statuses
  }, {})

const getAmenitiesFormInitialValues = amenities => ({
  statuses: getStatuses(amenities),
})

export default getAmenitiesFormInitialValues
