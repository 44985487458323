import React from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip as TooltipContainer,
  TooltipIcon,
  TooltipDropdown,
} from './styled'

const Tooltip = ({ icon, description }) => (
  <TooltipContainer>
    <TooltipIcon icon={icon} />
    <TooltipDropdown>{description}</TooltipDropdown>
  </TooltipContainer>
)

Tooltip.propTypes = {
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Tooltip
