import styled from 'styled-components'

export const Input = styled.input`
  width: 100%;
  padding: 2px 2px 8px 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  border-radius: 0;
  line-height: 22px;
  font-size: 14px;

  &:focus {
    border-bottom-color: ${props => props.theme.brandPrimary};
  }

  &:disabled {
    color: ${props => props.theme.grayDarker};
  }

  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.grayDark};
  }
`
