import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../core/components/styled'
import {
  ProviderPublishToolbar as ProviderPublishToolbarContainer,
  ProviderPublishToolbarActions,
  ProviderPublishToolbarDescription,
} from '../../styled'
import { getFrontendBaseURL } from '../../../../core/services/environment'

const ProviderPublishToolbar = ({
  providerId,
  display,
  existsUnapprovedPublishedContent,
  isPublishingProvider,
  handleProviderPublish,
}) => (
  <ProviderPublishToolbarContainer isVisible={display}>
    <ProviderPublishToolbarDescription>
      {existsUnapprovedPublishedContent
        ? 'Your content is already pending review. Any content you will publish from now on will be part of the review as well. Hit preview to see what the site admin will see at the time of the review.'
        : 'You have some unpublished changes. Publish the content and wait for the admin to review it.'}
    </ProviderPublishToolbarDescription>

    <ProviderPublishToolbarActions>
      <Button
        as="a"
        href={`${getFrontendBaseURL()}/communities/${providerId}/preview?preview=true`}
        target="blank"
        color="primary"
        line
        margin="no xSmall no no"
      >
        Preview
      </Button>
      <Button
        type="button"
        color="primary"
        disabled={isPublishingProvider}
        onClick={handleProviderPublish}
      >
        {isPublishingProvider ? 'Publishing' : 'Publish'}
      </Button>
    </ProviderPublishToolbarActions>
  </ProviderPublishToolbarContainer>
)

ProviderPublishToolbar.propTypes = {
  providerId: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
  existsUnapprovedPublishedContent: PropTypes.bool.isRequired,
  isPublishingProvider: PropTypes.bool.isRequired,
  handleProviderPublish: PropTypes.func.isRequired,
}

export default ProviderPublishToolbar
