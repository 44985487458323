import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import {
  Panel,
  Grid,
  GridColumn,
  Button,
} from '../../../core/components/styled'
import { Input, Select, Checkbox } from '../../../core/components'
import { CategoryMultiSelect } from './sections'

const ProviderFiltersForm = ({ handleSubmit }) => (
  <Panel padding="small" margin="no no medium">
    <form onSubmit={handleSubmit}>
      <Grid multiLine>
        <GridColumn size="3/12" padding="no xSmall no no">
          <Field name="centerPoint" label="Center point" component={Input} />
        </GridColumn>

        <GridColumn size="3/12" padding="no xSmall no no">
          <Field name="radius" label="Radius" component={Input} />
        </GridColumn>

        <GridColumn size="3/12" padding="no xSmall no no">
          <Field name="noOfCitations" label="Citations" component={Input} />
        </GridColumn>

        <GridColumn size="3/12" padding="no xSmall no no">
          <Field name="facilityType" label="Facility type" component={Select}>
            <option value="" />
            <option value="home">Home</option>
            <option value="center">Center</option>
            <option value="other">Other</option>
          </Field>
        </GridColumn>

        <GridColumn size="3/12" padding="no xSmall no no">
          <Field name="namePart" label="Name" component={Input} />
        </GridColumn>

        <GridColumn size="3/12" padding="no xSmall no no">
          <Field name="addressPart" label="Address" component={Input} />
        </GridColumn>

        <GridColumn size="6/12" padding="no xSmall no no">
          <Field name="notePart" label="Notes" component={Input} />
        </GridColumn>

        <GridColumn size="6/12" padding="no xSmall no no">
          <CategoryMultiSelect name="categories" placeholder="Categories" />
        </GridColumn>

        <GridColumn size="3/12" padding="no xSmall no no">
          <Field
            name="onlyMedicaidCertified"
            label="onlyMedicaid Certified"
            component={Checkbox}
          />
        </GridColumn>

        <GridColumn size="2/12" padding="no xSmall no no">
          <Field
            name="onlyFeatured"
            label="Only featured"
            component={Checkbox}
          />
        </GridColumn>

        <GridColumn size="1/12" padding="no" align="right">
          <Button type="submit" color="primary">
            Search
          </Button>
        </GridColumn>
      </Grid>
    </form>
  </Panel>
)

ProviderFiltersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default ProviderFiltersForm
