import * as Yup from 'yup'
import { VALIDATION_REGEXES } from '../../utils/constants'

const accountSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please povide a valid email address.')
    .required('Please provide your email address.'),

  phoneNumber: Yup.string()
    .matches(
      VALIDATION_REGEXES.PHONE_NUMBER,
      'Please provide a valid phone number.',
    )
    .required('Please provide your phone number.'),

  firstName: Yup.string().required('Please provide your first name.'),
  lastName: Yup.string().required('Please provide your last name.'),
  state: Yup.string().required('Please provide your state.'),
  city: Yup.string().required('Please provide your city.'),
  address: Yup.string().required('Please provide your street address.'),
  zipCode: Yup.string().required('Please provide your zip code.'),
})

export default accountSchema
