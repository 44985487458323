import qs from 'qs'
import { http } from '../../core/services'

export const verifyAccount = queryParams =>
  http.get(`users/confirmation${queryParams}`).then(response => response.data)

export const triggerFlowAccountForgotPassword = email =>
  http
    .post('users/password/reset/start-flow', { email })
    .then(response => response.data)

export const resetAccountPassword = (data, queryParams) => {
  const { token, email } = qs.parse(queryParams, { ignoreQueryPrefix: true })
  return http
    .patch('users/password/reset', { resetToken: token, email, ...data })
    .then(response => response.data)
}

export const changeAccountPassword = data =>
  http.patch('users/password/change/self', data).then(response => response.data)

export const loadAccount = () =>
  http.get('users/details/self').then(response => response.data)

export const updateAccount = data =>
  http.patch('users/details/self', data).then(response => response.data)
