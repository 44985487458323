import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { find } from 'lodash-es'
import { theme } from '../styles'
import { FormGroup, FormError } from './styled'

const styles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    padding: '2px 0 4px 0',
    boxShadow: 'none',
    borderRadius: 0,
    borderWidth: '0 0 1px',
    borderColor: isFocused ? theme.brandPrimary : theme.grayLight,
    outline: 'none',
    '&:hover': isFocused ? theme.brandPrimary : theme.grayLight,
  }),

  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  dropdownIndicator: provided => ({
    ...provided,
    paddingRight: 0,
    color: theme.brandPrimary,
  }),

  placeholder: provided => ({
    ...provided,
    color: theme.grayDark,
  }),
}

const MultiSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  placeholder,
  margin,
  disabled,
  options,
  ...props
}) => {
  const onChange = useCallback(
    selectedOptions => {
      const value = (selectedOptions || []).map(({ value }) => value)
      setFieldValue(field.name, value)
    },
    [field.name, setFieldValue],
  )

  const defaultValue = useMemo(
    () =>
      field.value.reduce((defaultValue, value) => {
        defaultValue.push(find(options, { value }))
        return defaultValue
      }, []),
    [field.value, options],
  )

  return (
    <FormGroup hasValue={!!field.value} margin={margin}>
      <Select
        defaultValue={defaultValue}
        isMulti
        isClearable={false}
        name={field.name}
        options={options}
        placeholder={placeholder}
        styles={styles}
        onChange={onChange}
        isDisabled={disabled}
        {...props}
      />

      {touched[field.name] && errors[field.name] && (
        <FormError>{errors[field.name]}</FormError>
      )}
    </FormGroup>
  )
}

MultiSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
}

MultiSelect.defaultProps = {
  options: [],
  placeholder: undefined,
  margin: undefined,
  disabled: false,
}

export default MultiSelect
