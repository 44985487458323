import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../core/components/styled'

const ClaimsTableActions = ({ row, handleClaimApproval }) => {
  const doApproveClaim = useCallback(() => {
    handleClaimApproval(row.original.id)
  }, [row.original.id, handleClaimApproval])

  return (
    <Button color="primary" onClick={doApproveClaim}>
      Approve
    </Button>
  )
}

ClaimsTableActions.propTypes = {
  row: PropTypes.object.isRequired,
  handleClaimApproval: PropTypes.func.isRequired,
}

export default ClaimsTableActions
