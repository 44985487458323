import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Resolver } from '../../../core/components'
import { PageLoading } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { isAdmin } from '../../../account/ducks'
import {
  loadProvider,
  loadProviderChecksum,
  loadAmenities,
  loadImages,
  loadNotes,
  resetProvider,
  resetAmenities,
  resetImages,
  resetNotes,
} from '../../ducks'
import ProviderEditorPage from './ProviderEditorPage'

const ProviderEditorResolver = ({
  isAdmin,
  match,
  loadProvider,
  loadProviderChecksum,
  loadAmenities,
  loadImages,
  loadNotes,
  resetProvider,
  resetAmenities,
  resetImages,
  resetNotes,
}) => {
  const { providerId } = match.params
  const resolve = useCallback(
    () =>
      Promise.all([
        loadProvider(providerId),
        loadProviderChecksum(providerId),
        loadAmenities(providerId),
        loadImages(providerId),
        isAdmin ? loadNotes(providerId) : '',
      ]),
    [
      providerId,
      loadProvider,
      loadProviderChecksum,
      loadAmenities,
      loadImages,
      loadNotes,
      isAdmin,
    ],
  )

  useEffect(
    () => () => {
      resetProvider()
      resetAmenities()
      resetImages()
      resetNotes()
    },
    [resetProvider, resetAmenities, resetImages, resetNotes],
  )

  return (
    <>
      <DocumentTitle>Edit provider</DocumentTitle>
      <Resolver
        successComponent={ProviderEditorPage}
        loadingComponent={PageLoading}
        resolve={resolve}
      />
    </>
  )
}

ProviderEditorResolver.propTypes = {
  match: RouterPropTypes.match.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loadProvider: PropTypes.func.isRequired,
  loadProviderChecksum: PropTypes.func.isRequired,
  loadAmenities: PropTypes.func.isRequired,
  loadImages: PropTypes.func.isRequired,
  loadNotes: PropTypes.func.isRequired,
  resetProvider: PropTypes.func.isRequired,
  resetAmenities: PropTypes.func.isRequired,
  resetImages: PropTypes.func.isRequired,
  resetNotes: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state.account.login.user),
})

const mapDispatchToProps = {
  loadProvider,
  loadProviderChecksum,
  loadAmenities,
  loadImages,
  loadNotes,
  resetProvider,
  resetAmenities,
  resetImages,
  resetNotes,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProviderEditorResolver),
)
