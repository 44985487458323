import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  TableActions,
  TableAction,
  TableActionIcon,
} from '../../../../core/components/styled'

const ProvidersTableActions = ({ row }) => {
  const { providerId, isProviderClaimApproved } = row.original
  if (!isProviderClaimApproved) return null

  return (
    <TableActions>
      <TableAction as={Link} to={`/providers/${providerId}`}>
        <TableActionIcon icon="edit" />
      </TableAction>
    </TableActions>
  )
}

ProvidersTableActions.propTypes = {
  row: PropTypes.object.isRequired,
}

export default ProvidersTableActions
