const getProviderFiltersFormInitialValues = () => ({
  centerPoint: '',
  radius: '',
  noOfCitations: '',
  facilityType: '',
  categories: [],
  namePart: '',
  addressPart: '',
  notePart: '',
  onlyMedicaidCertified: false,
  onlyFeatured: false,
})

export default getProviderFiltersFormInitialValues
