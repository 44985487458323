import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { LOGIN_FAIL_REASONS } from '../../constants'
import { login, resetLogin } from '../../ducks'
import { Message } from '../../../core/components/styled'
import { Page } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { loginSchema } from '../../schemas'
import { AccountPanel, AccountPanelTitle } from '../styled'
import { LoginForm } from '../forms'

const LoginPage = ({
  isLoginFailed,
  loginFailReason,
  isLoggingIn,
  login,
  resetLogin,
}) => {
  const handleSubmit = useCallback(
    ({ email, password }) => login(email, password),
    [login],
  )

  useEffect(() => resetLogin, [resetLogin])

  return (
    <>
      <DocumentTitle>Login</DocumentTitle>
      <Page>
        <AccountPanel isLoading={isLoggingIn}>
          <AccountPanelTitle>Log in</AccountPanelTitle>

          <Formik
            render={LoginForm}
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          />

          {isLoginFailed && (
            <Message animated margin="medium no no" color="alert">
              {loginFailReason === LOGIN_FAIL_REASONS.USER_NOT_FOUND
                ? 'Sorry, we don’t recognize this email, have you registered?'
                : 'Invalid credentials.'}
            </Message>
          )}
        </AccountPanel>
      </Page>
    </>
  )
}

LoginPage.propTypes = {
  isLoginFailed: PropTypes.bool.isRequired,
  loginFailReason: PropTypes.string,
  isLoggingIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  resetLogin: PropTypes.func.isRequired,
}

LoginPage.defaultProps = {
  loginFailReason: undefined,
}

const mapStateToProps = state => ({
  isLoggedIn: state.account.login.isLoggedIn,
  isLoginFailed: state.account.login.isLoginFailed,
  loginFailReason: state.account.login.loginFailReason,
  isLoggingIn: state.account.login.isLoggingIn,
})

const mapDispatchToProps = { login, resetLogin }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage)
