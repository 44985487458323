import update from 'immutability-helper'
import { register as doRegister } from '../services'

// Actions
const START_REGISTER = 'account/register/START_REGISTER'
const COMPLETE_REGISTER = 'account/register/COMPLETE_REGISTER'
const FAIL_REGISTER = 'account/register/FAIL_REGISTER'
const RESET = 'account/register/RESET'

// Initial state

const initialState = {
  isRegistering: false,
  isRegistered: false,
  isRegisterFailed: false,
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REGISTER:
      return update(state, {
        $merge: { isRegisterFailed: false, isRegistering: true },
      })

    case COMPLETE_REGISTER:
      return update(state, {
        $merge: { isRegistering: false, isRegistered: true },
      })

    case FAIL_REGISTER:
      return update(state, {
        $merge: { isRegisterFailed: true, isRegistering: false },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startRegister = () => ({ type: START_REGISTER })
const completeRegister = () => ({ type: COMPLETE_REGISTER })
const failRegister = () => ({ type: FAIL_REGISTER })

export const register = data => dispatch => {
  dispatch(startRegister())
  const registerPromise = doRegister(data)

  registerPromise
    .then(() => dispatch(completeRegister()))
    .catch(() => dispatch(failRegister()))

  return registerPromise
}

export const resetRegister = () => ({ type: RESET })
