import React from 'react'
import PropTypes from 'prop-types'

const renderIcon = icon => {
  switch (icon) {
    case 'add':
      return (
        <path
          d="M30 14H18V2c0-1.2-.8-2-2-2s-2 .8-2 2v12H2c-1.2 0-2 .8-2 2s.8 2 2 2h12v12c0 1.2.8 2 2 2s2-.8 2-2V18h12c1.2 0 2-.8 2-2s-.8-2-2-2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )
    case 'close':
      return (
        <g
          stroke="currentColor"
          strokeWidth="5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <path d="M28 4L4 28M28 28L4 4" />
        </g>
      )

    case 'edit':
      return (
        <path
          d="M.023 31.299l1.469-4.897A5.815 5.815 0 0 1 2.95 23.96L21.617 5.29a.727.727 0 0 1 1.028 0l4.06 4.062a.727.727 0 0 1 0 1.028L8.038 29.049a5.82 5.82 0 0 1-2.443 1.46L.702 31.977a.546.546 0 0 1-.679-.678zM29.365.637l1.999 1.998c.848.849.848 2.224 0 3.073l-2.436 2.435a.724.724 0 0 1-1.025 0l-4.047-4.047a.725.725 0 0 1 0-1.024L26.292.636a2.172 2.172 0 0 1 3.073 0z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )

    case 'delete':
      return (
        <path
          d="M31.064.936a3.196 3.196 0 0 0-4.52 0L16.02 11.46 5.495.936a3.196 3.196 0 0 0-4.52 4.52L11.502 15.98.976 26.505a3.196 3.196 0 1 0 4.52 4.52L16.02 20.498l10.525 10.525a3.196 3.196 0 0 0 4.52-4.52L20.538 15.98 31.064 5.455a3.196 3.196 0 0 0 0-4.52z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )

    case 'star':
      return (
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M16 1l4.697 10.114L32 12.35l-8.4 7.487 2.289 10.877L16 25.228l-9.889 5.486L8.4 19.837 0 12.35l11.303-1.236z"
        />
      )

    case 'notes':
      return (
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <path d="M1 3h30v26H1zM8 10h16M8 16h16M8 22h8" />
        </g>
      )

    case 'search':
      return (
        <g
          transform="translate(1 1)"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <path d="M29 29l-7.733-7.733" />
          <circle cx="12.429" cy="12.429" r="12.429" />
        </g>
      )

    case 'back':
      return (
        <path
          d="M8.67 14.07L20.097 2.642a2.286 2.286 0 0 1 3.232 3.232l-9.813 9.812L23.33 25.5a2.286 2.286 0 1 1-3.232 3.232L8.669 17.302a2.286 2.286 0 0 1 0-3.232z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )

    case 'next':
      return (
        <path
          d="M23.358 14.07L11.93 2.642a2.286 2.286 0 0 0-3.232 3.232l9.812 9.812L8.698 25.5a2.286 2.286 0 1 0 3.232 3.232l11.428-11.429a2.286 2.286 0 0 0 0-3.232z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )

    case 'backward':
      return (
        <g fill="currentColor" fillRule="nonzero">
          <path d="M1.67 14.07L13.097 2.642a2.286 2.286 0 0 1 3.232 3.232l-9.813 9.812L16.33 25.5a2.286 2.286 0 1 1-3.232 3.232L1.669 17.302a2.286 2.286 0 0 1 0-3.232z" />
          <path d="M15.384 14.07L26.812 2.642a2.286 2.286 0 0 1 3.232 3.232l-9.812 9.812 9.812 9.813a2.286 2.286 0 1 1-3.232 3.232L15.384 17.302a2.286 2.286 0 0 1 0-3.232z" />
        </g>
      )

    case 'forward':
      return (
        <g fill="currentColor" fillRule="nonzero">
          <path d="M16.358 14.07L4.93 2.642a2.286 2.286 0 0 0-3.232 3.232l9.812 9.812L1.698 25.5A2.286 2.286 0 1 0 4.93 28.73l11.428-11.429a2.286 2.286 0 0 0 0-3.232z" />
          <path d="M30.073 14.07L18.644 2.642a2.286 2.286 0 0 0-3.232 3.232l9.813 9.812-9.813 9.813a2.286 2.286 0 1 0 3.232 3.232l11.429-11.429a2.286 2.286 0 0 0 0-3.232z" />
        </g>
      )

    case 'more':
      return (
        <path
          fill="currentColor"
          d="M18 12c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3zm0 3c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
        />
      )

    default:
      return false
  }
}

const Icon = ({ className, icon }) => (
  <svg
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {renderIcon(icon)}
  </svg>
)

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  className: undefined,
}

export default Icon
