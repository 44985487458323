import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { withRouter } from 'react-router'
import { Page as PageContainer, PageContent } from './styled'
import NavigationBar from './NavigationBar'

const Page = ({ location, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <PageContainer>
      <NavigationBar />
      <PageContent>{children}</PageContent>
    </PageContainer>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  location: RouterPropTypes.location.isRequired,
}

export default withRouter(Page)
