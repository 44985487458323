import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Map } from '../../../../core/components'
import { ProviderMap as ProviderMapContainer } from '../../styled'

const ProviderMap = ({ latitude, longitude }) => {
  const coordinates = useMemo(() => ({ latitude, longitude }), [
    latitude,
    longitude,
  ])

  return (
    <ProviderMapContainer>
      <Map coordinates={coordinates} zoom={15} />
    </ProviderMapContainer>
  )
}

ProviderMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
}

export default ProviderMap
