import { pickBy, isNil } from 'lodash-es'
import qs from 'qs'
import { http } from '../../core/services'
import { EMPTY_PROVIDER } from '../constants'

export const searchProviders = (filters, offset, limit) =>
  http
    .get('management/providers/search', {
      params: { ...filters, offset, limit },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    .then(response => {
      const count = Number(response.headers['x-total-count'])

      return {
        count,
        pageCount: Math.ceil(count / limit),
        providers: response.data,
      }
    })

export const loadProviders = () =>
  http.get('management/providers').then(response => response.data)

export const loadProvider = id =>
  http.get(`management/providers/${id}/info`).then(response => ({
    id,
    ...EMPTY_PROVIDER,
    ...pickBy(response.data, value => !isNil(value)),
  }))

export const createProvider = data =>
  http.post('management/providers/info', data).then(response => response.data)

export const updateProvider = (id, data) =>
  http.patch(`management/providers/${id}/info`, data).then(response => ({
    checksum: response.headers['x-content-checksum'],
  }))

export const loadProviderChecksum = providerId =>
  http
    .get(`management/providers/${providerId}/checksum`)
    .then(response => response.data)

export const publishProvider = providerId =>
  http
    .post(`management/providers/${providerId}/publish`)
    .then(response => response.data)

export const claimProvider = providerId =>
  http.post(`management/providers/${providerId}/claim`)
