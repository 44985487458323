import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'
import { RICH_TEXT_EDITOR_BUTTONS } from '../constants'

const RichTextInput = ({ field, form }) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(field.value, 'html'),
  )

  const onChange = useCallback(
    value => {
      setValue(value)
      form.setFieldValue(field.name, value.toString('html'))
    },
    [setValue, form, field.name],
  )

  return (
    <RichTextEditor
      value={value}
      onChange={onChange}
      toolbarConfig={RICH_TEXT_EDITOR_BUTTONS}
    />
  )
}

RichTextInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default RichTextInput
