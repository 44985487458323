import * as Yup from 'yup'

const providerSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  facilityCode: Yup.string().required('This field is required.'),
  licenseCode: Yup.string().required('This field is required.'),
  categories: Yup.array()
    .min(1, 'Choose at least one category')
    .required('This field is required.'),
})

export default providerSchema
