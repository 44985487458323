import styled, { css } from 'styled-components'
import { mapper, sizeMapper, alignMapper } from '../../../utils/styles'

export const COLUMN_SIZES = {
  '0/12': '0',
  '1/12': '8.33%',
  '2/12': '16.66%',
  '3/12': '25%',
  '4/12': '33.33%',
  '5/12': '41.66%',
  '6/12': '50%',
  '7/12': '58.33%',
  '8/12': '66.66%',
  '9/12': '75%',
  '10/12': '83.33%',
  '11/12': '91.66%',
  '12/12': '100%',
}

export const GridColumn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};

  ${props =>
    props.size &&
    css`
      width: ${mapper(props.size, COLUMN_SIZES)};
    `};

  ${props =>
    props.shift &&
    css`
      margin-left: ${mapper(props.shift, COLUMN_SIZES)};
    `};

  ${props =>
    props.align &&
    css`
      justify-content: ${alignMapper(props)};
    `}
`

export const Grid = styled.div`
  display: flex;
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `};

  ${props =>
    props.multiLine &&
    css`
      flex-wrap: wrap;
    `};
`
