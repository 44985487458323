import styled, { css } from 'styled-components'
import { loading } from '../../../core/styles'

export const PageTitle = styled.h1`
  margin: 0;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 28px;
`

export const PageHeaderActions = styled.div`
  display: flex;
  align-items: flex-start;
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`

export const PageContent = styled.div`
  width: 80vw;
  margin: 0 auto 80px;

  ${props =>
    props.compact &&
    css`
      width: 1200px;
    `}
`

export const PageLoading = styled.div`
  position: relative;
  height: calc(100vh - 60px);

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    ${loading('24px')};
  }
`

export const Page = styled.div`
  position: relative;
  padding-top: 80px;
`
