import update from 'immutability-helper'
import memoize from 'memoize-one'
import { ROLES, LOGIN_FAIL_REASONS } from '../constants'
import { login as doLogin, logout as doLogout, getUser } from '../services'

// Actions
const START_LOGIN = 'account/login/START_LOGIN'
const COMPLETE_LOGIN = 'account/login/COMPLETE_LOGIN'
const FAIL_LOGIN = 'account/login/FAIL_LOGIN'
const COMPLETE_LOGOUT = 'account/login/COMPLETE_LOGOUT'
const RESET = 'account/login/RESET'

// Initial state
const user = getUser()

const initialState = {
  isLoggingIn: false,
  isLoggedIn: !!user,
  isLoginFailed: false,
  loginFailReason: undefined,
  user,
}

// Helpers
export const isAdmin = memoize(user => user && user.role.includes(ROLES.ADMIN))

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          loginFailReason: undefined,
          isLoggingIn: true,
          isLoggedIn: false,
        },
      })

    case COMPLETE_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          loginFailReason: undefined,
          isLoggingIn: false,
          isLoggedIn: true,
          user: action.user,
        },
      })

    case FAIL_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: true,
          loginFailReason: action.loginFailReason,
          isLoggingIn: false,
          isLoggedIn: false,
        },
      })

    case COMPLETE_LOGOUT:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          loginFailReason: undefined,
          isLoggingIn: false,
          isLoggedIn: false,
          user: undefined,
        },
      })

    case RESET:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          loginFailReason: undefined,
          isLoggingIn: false,
        },
      })

    default:
      return state
  }
}

// Action creators
const startLogin = () => ({ type: START_LOGIN })
const completeLogin = user => ({ type: COMPLETE_LOGIN, user })

const failLogin = error => {
  const loginFailReason =
    error.response && error.response.status === 404
      ? LOGIN_FAIL_REASONS.USER_NOT_FOUND
      : LOGIN_FAIL_REASONS.INCORRECT_CREDENTIALS

  return { type: FAIL_LOGIN, loginFailReason }
}

const completeLogout = () => ({ type: COMPLETE_LOGOUT })

export const login = (email, password) => dispatch => {
  dispatch(startLogin())
  const loginPromise = doLogin(email, password)

  loginPromise
    .then(user => dispatch(completeLogin(user)))
    .catch(error => dispatch(failLogin(error)))

  return loginPromise
}

export const logout = () => dispatch => {
  doLogout()
  dispatch(completeLogout())
}

export const resetLogin = () => ({ type: RESET })
