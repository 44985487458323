import { useEffect } from 'react'
import PropTypes from 'prop-types'

const DocumentTitle = ({ children }) => {
  useEffect(() => {
    document.title = `${children} - Loved Ones First`
  })

  return null
}

DocumentTitle.propTypes = {
  children: PropTypes.node,
}

DocumentTitle.defaultProps = {
  children: undefined,
}

export default DocumentTitle
