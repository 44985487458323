/* global google */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, withGoogleMap } from 'react-google-maps'
import { googleMap } from './hoc'
import MapMarker from './MapMarker'

const Map = ({ coordinates, zoom }) => {
  const mapCenter = useMemo(
    () => new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
    [coordinates],
  )

  return (
    <GoogleMap zoom={zoom} center={mapCenter}>
      <MapMarker coordinates={coordinates} />
    </GoogleMap>
  )
}

Map.propTypes = {
  coordinates: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
}

export default googleMap(withGoogleMap(Map))
