import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { AdminGuard, ProviderGuard } from '../../account/components/guards'
import {
  NavigationBar as NavigationBarContainer,
  NavigationBarItems,
  NavigationBarItem,
  NavigationBarItemText,
  NavigationBarItemIcon,
  NavigationBarDropdown,
  NavigationBarDropdownItem,
} from './styled'

const NavigationBar = ({ givenName }) => {
  const isProviderLinkActive = useCallback(
    (match, location) => location.pathname.includes('providers'),
    [],
  )

  return (
    <NavigationBarContainer>
      <AdminGuard>
        <NavigationBarItems>
          <NavigationBarItem>
            <NavigationBarItemText
              as={NavLink}
              isActive={isProviderLinkActive}
              to="/providers/search"
            >
              Providers
            </NavigationBarItemText>
          </NavigationBarItem>

          <NavigationBarItem>
            <NavigationBarItemText as={NavLink} to="/moderation/claims">
              Claims
            </NavigationBarItemText>
          </NavigationBarItem>

          <NavigationBarItem>
            <NavigationBarItemText as={NavLink} to="/moderation/publications">
              Publications
            </NavigationBarItemText>
          </NavigationBarItem>
        </NavigationBarItems>
      </AdminGuard>

      <ProviderGuard>
        <NavigationBarItems>
          <NavigationBarItem>
            <NavigationBarItemText as={NavLink} to="/providers">
              Providers
            </NavigationBarItemText>
          </NavigationBarItem>
        </NavigationBarItems>
      </ProviderGuard>

      <NavigationBarItems>
        <NavigationBarItem>
          <NavigationBarItemText>
            Welcome back, {givenName}
          </NavigationBarItemText>
          <NavigationBarItemIcon icon="more" />
          <NavigationBarDropdown align="right">
            <NavigationBarDropdownItem to="/account/password/change">
              Change your password
            </NavigationBarDropdownItem>
            <NavigationBarDropdownItem to="/account/edit">
              Edit account
            </NavigationBarDropdownItem>
            <NavigationBarDropdownItem to="/account/logout">
              Logout
            </NavigationBarDropdownItem>
          </NavigationBarDropdown>
        </NavigationBarItem>
      </NavigationBarItems>
    </NavigationBarContainer>
  )
}

NavigationBar.propTypes = {
  givenName: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  givenName: state.account.login.user.given_name,
})

export default connect(mapStateToProps)(NavigationBar)
