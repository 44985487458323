import styled, { css } from 'styled-components'
import { Input } from './Input'

export const Select = styled(Input).attrs({ as: 'select' })`
  appearance: none;
  padding-right: 36px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMyZDU3ODEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utb3BhY2l0eT0iLjgiLz48L3N2Zz4=')
    right 2px center no-repeat;

  ${props =>
    props.notSelected &&
    css`
      color: ${props.theme.grayDark};
    `}

  &::-ms-expand {
    display: none;
  }
`
