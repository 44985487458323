import update from 'immutability-helper'
import {
  verifyAccount as doVerifyAccount,
  triggerFlowAccountForgotPassword as doTriggerFlowAccountForgotPassword,
  resetAccountPassword as doResetAccountPassword,
  changeAccountPassword as doChangeAccountPassword,
  loadAccount as doLoadAccount,
  updateAccount as doUpdateAccount,
} from '../services'

// Actions
const START_VERIFICATION = 'account/START_VERIFICATION'
const COMPLETE_VERIFICATION = 'account/COMPLETE_VERIFICATION'
const FAIL_VERIFICATION = 'account/FAIL_VERIFICATION'

const START_FORGOT_PASSWORD = 'account/START_FORGOT_PASSWORD'
const COMPLETE_FORGOT_PASSWORD = 'account/COMPLETE_FORGOT_PASSWORD'
const FAIL_FORGOT_PASSWORD = 'account/FAIL_FORGOT_PASSWORD'

const START_RESET_PASSWORD = 'account/START_RESET_PASSWORD'
const COMPLETE_RESET_PASSWORD = 'account/COMPLETE_RESET_PASSWORD'
const FAIL_RESET_PASSWORD = 'account/FAIL_RESET_PASSWORD'

const START_CHANGE_PASSWORD = 'account/START_CHANGE_PASSWORD'
const COMPLETE_CHANGE_PASSWORD = 'account/COMPLETE_CHANGE_PASSWORD'
const FAIL_CHANGE_PASSWORD = 'account/FAIL_CHANGE_PASSWORD'

const START_LOAD_ACCOUNT = 'account/START_LOAD_ACCOUNT'
const COMPLETE_LOAD_ACCOUNT = 'account/COMPLETE_LOAD_ACCOUNT'
const FAIL_LOAD_ACCOUNT = 'account/FAIL_LOAD_ACCOUNT'

const START_UPDATE_ACCOUNT = 'account/START_UPDATE_ACCOUNT'
const COMPLETE_UPDATE_ACCOUNT = 'account/COMPLETE_UPDATE_ACCOUNT'
const FAIL_UPDATE_ACCOUNT = 'account/FAIL_UPDATE_ACCOUNT'

const RESET = 'account/RESET'

// Initial state
const initialState = {
  isVerifying: false,
  isVerificationFailed: false,
  isTriggeringForgotPassword: false,
  isTriggerForgotPassword: false,
  isTriggerForgotPasswordFailed: false,
  isResettingPassword: false,
  isResetPassword: false,
  isResetPasswordFailed: false,
  isChangingPassword: false,
  isChangePassword: false,
  isChangePasswordFailed: false,
  isLoadingAccount: false,
  isLoadAccountFailed: false,
  account: undefined,
  isUpdatingAccount: false,
  isAccountUpdated: false,
  isUpdateAccountFailed: false,
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_VERIFICATION:
      return update(state, {
        $merge: { isVerifying: true, isVerificationFailed: false },
      })

    case COMPLETE_VERIFICATION: {
      return update(state, {
        isVerifying: { $set: false },
      })
    }

    case FAIL_VERIFICATION:
      return update(state, {
        $merge: { isVerifying: false, isVerificationFailed: true },
      })

    case START_FORGOT_PASSWORD:
      return update(state, {
        $merge: {
          isTriggeringForgotPassword: true,
          isTriggerForgotPasswordFailed: false,
        },
      })

    case COMPLETE_FORGOT_PASSWORD:
      return update(state, {
        $merge: {
          isTriggeringForgotPassword: false,
          isTriggerForgotPassword: true,
        },
      })

    case FAIL_FORGOT_PASSWORD:
      return update(state, {
        $merge: {
          isTriggeringForgotPassword: false,
          isTriggerForgotPasswordFailed: true,
        },
      })

    case START_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResetPasswordFailed: false,
          isResettingPassword: true,
        },
      })

    case COMPLETE_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResettingPassword: false,
          isResetPassword: true,
        },
      })

    case FAIL_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResetPasswordFailed: true,
          isResettingPassword: false,
        },
      })

    case START_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          isChangePasswordFailed: false,
          isChangingPassword: true,
        },
      })

    case COMPLETE_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          isChangingPassword: false,
          isChangePassword: true,
        },
      })

    case FAIL_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          isChangePasswordFailed: true,
          isChangingPassword: false,
        },
      })

    case START_LOAD_ACCOUNT:
      return update(state, {
        $merge: {
          isLoadingAccount: true,
          isLoadAccountFailed: false,
        },
      })

    case COMPLETE_LOAD_ACCOUNT:
      return update(state, {
        $merge: {
          isLoadingAccount: false,
          account: action.account,
        },
      })

    case FAIL_LOAD_ACCOUNT:
      return update(state, {
        $merge: {
          isLoadAccountFailed: true,
          isLoadingAccount: false,
        },
      })

    case START_UPDATE_ACCOUNT:
      return update(state, {
        $merge: {
          isUpdatingAccount: true,
          isUpdateAccountFailed: false,
        },
      })

    case COMPLETE_UPDATE_ACCOUNT:
      return update(state, {
        $merge: {
          isUpdatingAccount: false,
        },
      })

    case FAIL_UPDATE_ACCOUNT:
      return update(state, {
        $merge: {
          isUpdateAccountFailed: true,
          isUpdatingAccount: false,
        },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startAccountVerification = () => ({ type: START_VERIFICATION })
const completeAccountVerification = () => ({ type: COMPLETE_VERIFICATION })
const failAccountVerification = () => ({ type: FAIL_VERIFICATION })

export const verifyAccount = queryParams => dispatch => {
  dispatch(startAccountVerification())
  const verifyAccountPromise = doVerifyAccount(queryParams)

  verifyAccountPromise
    .then(() => dispatch(completeAccountVerification()))
    .catch(() => dispatch(failAccountVerification()))

  return verifyAccountPromise
}

const startAccountForgotPassword = () => ({ type: START_FORGOT_PASSWORD })
const completeAccountForgotPassword = () => ({ type: COMPLETE_FORGOT_PASSWORD })
const failAccountForgotPassword = () => ({ type: FAIL_FORGOT_PASSWORD })

export const triggerFlowAccountForgotPassword = email => dispatch => {
  dispatch(startAccountForgotPassword())
  const triggerFlowAccountForgotPasswordPromise = doTriggerFlowAccountForgotPassword(
    email,
  )

  triggerFlowAccountForgotPasswordPromise
    .then(() => dispatch(completeAccountForgotPassword()))
    .catch(() => dispatch(failAccountForgotPassword()))

  return triggerFlowAccountForgotPasswordPromise
}

const startAccountResetPassword = () => ({ type: START_RESET_PASSWORD })
const completeAccountResetPassword = () => ({ type: COMPLETE_RESET_PASSWORD })
const failAccountResetPassword = () => ({ type: FAIL_RESET_PASSWORD })

export const resetAccountPassword = (data, queryParams) => dispatch => {
  dispatch(startAccountResetPassword())
  const resetAccountPasswordPromise = doResetAccountPassword(data, queryParams)

  resetAccountPasswordPromise
    .then(() => dispatch(completeAccountResetPassword()))
    .catch(() => dispatch(failAccountResetPassword()))

  return resetAccountPasswordPromise
}

const startAccountChangePassword = () => ({ type: START_CHANGE_PASSWORD })
const completeAccountChangePassword = () => ({ type: COMPLETE_CHANGE_PASSWORD })
const failAccountChangePassword = () => ({ type: FAIL_CHANGE_PASSWORD })

export const changeAccountPassword = (data, queryParams) => dispatch => {
  dispatch(startAccountChangePassword())
  const changeAccountPasswordPromise = doChangeAccountPassword(
    data,
    queryParams,
  )

  changeAccountPasswordPromise
    .then(() => dispatch(completeAccountChangePassword()))
    .catch(() => dispatch(failAccountChangePassword()))

  return changeAccountPasswordPromise
}

const startLoadAccount = () => ({ type: START_LOAD_ACCOUNT })
const completeLoadAccount = account => ({
  type: COMPLETE_LOAD_ACCOUNT,
  account,
})

const failLoadAccount = () => ({ type: FAIL_LOAD_ACCOUNT })

export const loadAccount = () => dispatch => {
  dispatch(startLoadAccount())
  const loadAccountPromise = doLoadAccount()

  loadAccountPromise
    .then(account => dispatch(completeLoadAccount(account)))
    .catch(() => dispatch(failLoadAccount()))

  return loadAccountPromise
}

const startUpdateAccount = () => ({ type: START_UPDATE_ACCOUNT })
const completeUpdateAccount = () => ({ type: COMPLETE_UPDATE_ACCOUNT })
const failUpdateAccount = () => ({ type: FAIL_UPDATE_ACCOUNT })

export const updateAccount = data => dispatch => {
  dispatch(startUpdateAccount())
  const updateAccountPromise = doUpdateAccount(data)

  updateAccountPromise
    .then(() => dispatch(completeUpdateAccount()))
    .catch(() => dispatch(failUpdateAccount()))

  return updateAccountPromise
}

export const resetAccount = () => ({ type: RESET })
