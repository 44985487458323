import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../core/components/styled'

const PublicationsTableActions = ({ row, handlePublicationApproval }) => {
  const doApprovePublication = useCallback(() => {
    handlePublicationApproval(row.original.providerId)
  }, [row.original.providerId, handlePublicationApproval])

  return (
    <Button color="primary" onClick={doApprovePublication}>
      Approve
    </Button>
  )
}

PublicationsTableActions.propTypes = {
  row: PropTypes.object.isRequired,
  handlePublicationApproval: PropTypes.func.isRequired,
}

export default PublicationsTableActions
