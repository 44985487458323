import styled from 'styled-components'
import { colorMapper } from '../../../utils/styles'

const StatusLabel = styled.span`
  display: inline-block;
  padding: 6px 8px;
  border: 1px solid ${colorMapper};
  border-radius: 4px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 13px;
  color: ${colorMapper};
`

export default StatusLabel
