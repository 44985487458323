import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Resolver } from '../../../core/components'
import { PageLoading } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { loadAccount } from '../../ducks'
import AccountEditorPage from './AccountEditorPage'

const AccountEditorResolver = ({ loadAccount }) => (
  <>
    <DocumentTitle>Edit Account</DocumentTitle>
    <Resolver
      successComponent={AccountEditorPage}
      loadingComponent={PageLoading}
      resolve={loadAccount}
    />
  </>
)

AccountEditorResolver.propTypes = {
  loadAccount: PropTypes.func.isRequired,
}

const mapDispatchToProps = { loadAccount }

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(AccountEditorResolver),
)
