import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash-es'
import {
  Dropzone as DropzoneContainer,
  DropzoneFileInput,
  DropzoneIcon,
  DropzoneDescription,
} from './styled'

const Dropzone = ({ description, onFilesSelected, ...props }) => {
  const onFileInputChange = useCallback(
    event => onFilesSelected([...event.target.files]),
    [onFilesSelected],
  )

  const onFilesDropped = useCallback(
    event => {
      event.preventDefault()
      const files = map(event.dataTransfer.items, item => item.getAsFile())
      onFilesSelected(files)
    },
    [onFilesSelected],
  )

  const onFilesDragOver = useCallback(event => event.preventDefault(), [])

  return (
    <DropzoneContainer
      onDrop={onFilesDropped}
      onDragOver={onFilesDragOver}
      {...props}
    >
      <DropzoneFileInput multiple onChange={onFileInputChange} />
      <DropzoneIcon />
      <DropzoneDescription>{description}</DropzoneDescription>
    </DropzoneContainer>
  )
}

Dropzone.propTypes = {
  description: PropTypes.string.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
}

export default Dropzone
