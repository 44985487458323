import { combineReducers } from 'redux'
import { reducer as claimsReducer } from './claims'
import { reducer as publicationsReducer } from './publications'

export { loadClaims, approveClaim, resetClaims } from './claims'
export {
  loadUnapprovedPublications,
  approvePublication,
  resetPublications,
} from './publications'

export const reducer = combineReducers({
  claims: claimsReducer,
  publications: publicationsReducer,
})
