import styled, { css } from 'styled-components'
import { sizeMapper } from '../../../utils/styles'
import FormLabel from './FormLabel'

const FormGroup = styled.div`
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no no small')};

  ${props =>
    props.hasValue &&
    css`
      ${FormLabel} {
        font-size: 12px;
        transform: none;
      }
    `};
`

export default FormGroup
