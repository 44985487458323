import update from 'immutability-helper'
import { findIndex } from 'lodash-es'
import {
  loadClaims as doLoadClaims,
  approveClaim as doApproveClaim,
} from '../services'

// Actions
const START_LOAD = 'claims/START_LOAD'
const COMPLETE_LOAD = 'claims/COMPLETE_LOAD'
const FAIL_LOAD = 'claims/FAIL_LOAD'
const START_APPROVE = 'claims/START_APPROVE'
const COMPLETE_APPROVE = 'claims/COMPLETE_APPROVE'
const FAIL_APPROVE = 'claims/FAIL_APPROVE'
const RESET = 'claims/RESET'

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  isApproving: false,
  isApproveFailed: false,
  claims: [],
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true, isLoadFailed: false },
      })

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          claims: action.claims,
        },
      })

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false, isLoadFailed: true },
      })

    case START_APPROVE:
      return update(state, {
        $merge: { isApproving: true, isApproveFailed: false },
      })

    case COMPLETE_APPROVE: {
      const index = findIndex(state.claims, { id: action.id })
      return update(state, {
        isApproving: { $set: false },
        claims: { $splice: [[index, 1]] },
      })
    }

    case FAIL_APPROVE:
      return update(state, {
        $merge: { isApproving: false, isApproveFailed: true },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startLoad = () => ({ type: START_LOAD })
const completeLoad = claims => ({ type: COMPLETE_LOAD, claims })
const failLoad = () => ({ type: FAIL_LOAD })
const startApprove = () => ({ type: START_APPROVE })
const completeApprove = id => ({ type: COMPLETE_APPROVE, id })
const failApprove = () => ({ type: FAIL_APPROVE })

export const loadClaims = () => dispatch => {
  dispatch(startLoad())
  const loadPromise = doLoadClaims()

  loadPromise
    .then(claims => dispatch(completeLoad(claims)))
    .catch(() => dispatch(failLoad()))

  return loadPromise
}

export const approveClaim = id => dispatch => {
  dispatch(startApprove())
  const approvePromise = doApproveClaim(id)

  approvePromise
    .then(() => dispatch(completeApprove(id)))
    .catch(() => dispatch(failApprove()))

  return approvePromise
}

export const resetClaims = () => ({ type: RESET })
