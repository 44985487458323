import update from 'immutability-helper'
import { findIndex, pick, uniqueId } from 'lodash-es'
import { COLOR_SUCCESS, COLOR_ALERT } from '../styles'

// Actions
const CREATE_NOTIFICATION = 'core/notifications/CREATE_NOTIFICATION'
const REMOVE_NOTIFICATION = 'core/notifications/REMOVE_NOTIFICATION'

// Initial state
const initialState = []

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return update(state, {
        $push: [pick(action, ['id', 'message', 'color', 'autoClose'])],
      })

    case REMOVE_NOTIFICATION: {
      const index = findIndex(state, { id: action.id })
      return update(state, { $splice: [[index, 1]] })
    }

    default:
      return state
  }
}

// Action creators
const addNotification = (id, message, color, autoClose) => ({
  type: CREATE_NOTIFICATION,
  id,
  message,
  color,
  autoClose,
})

export const removeNotification = id => ({
  type: REMOVE_NOTIFICATION,
  id,
})

const createNotification = (message, color, autoClose) => dispatch => {
  const id = uniqueId('notification-')
  dispatch(addNotification(id, message, color, autoClose))

  if (autoClose) {
    setTimeout(() => dispatch(removeNotification(id)), autoClose)
  }
}

export const createSuccessNotification = (message, autoClose = 5000) =>
  createNotification(message, COLOR_SUCCESS, autoClose)

export const createErrorNotification = (message, autoClose) =>
  createNotification(message, COLOR_ALERT, autoClose)
