import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '../../../../core/components'

const ProviderSearchTableNotes = ({ row }) => {
  const { notes } = row.original
  return notes ? <Tooltip icon="notes" description={notes} /> : null
}

ProviderSearchTableNotes.propTypes = {
  row: PropTypes.object.isRequired,
}

export default ProviderSearchTableNotes
