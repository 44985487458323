import {
  BASE_URL_MAP,
  API_BASE_URL_MAP,
  FRONTEND_BASE_URL_MAP,
  LOCAL,
} from '../constants'

export const getHost = () => window.location.host
export const getBaseUrl = () =>
  `${window.location.protocol}//${window.location.host}`

export const getEnvironment = () => BASE_URL_MAP[getHost()] || LOCAL
export const getApiBaseURL = () => API_BASE_URL_MAP[getEnvironment()]
export const getFrontendBaseURL = () => FRONTEND_BASE_URL_MAP[getEnvironment()]
