import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import {
  Panel,
  PanelHeader,
  PanelFooter,
  PanelTitle,
  PanelActions,
  PanelSection,
  PanelSectionTitle,
  Grid,
  GridColumn,
  Button,
} from '../../../core/components/styled'
import {
  Input,
  RichTextInput,
  Checkbox,
  StateSelect,
} from '../../../core/components'
import { CategoryMultiSelect } from './sections'

const ProviderEditorForm = ({
  isAdmin,
  isFullyManagedByAdmin,
  isNewProvider,
  isSavingProvider,
  dirty,
  handleSubmit,
}) => (
  <Panel margin="no no small" style={dirty ? { background: '#FFFFF5' } : {}}>
    <form onSubmit={handleSubmit}>
      <PanelHeader>
        <PanelTitle>Details</PanelTitle>
        <PanelActions>
          <Button type="submit" color="primary" disabled={!dirty}>
            {isSavingProvider ? 'Saving' : 'Save'}
          </Button>
        </PanelActions>
      </PanelHeader>

      <PanelSection borderless padding="sMedium sMedium no">
        <PanelSectionTitle>Basic information</PanelSectionTitle>
        <Grid multiLine>
          <GridColumn size="8/12" padding="no small no no">
            <Field
              name="name"
              label="Name"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="facilityCode"
              label="Facility Code"
              component={Input}
              disabled={!isNewProvider}
            />
          </GridColumn>

          <GridColumn size="8/12" padding="no small no no">
            <CategoryMultiSelect
              name="categories"
              placeholder="Categories"
              disabled={!isAdmin}
            />
          </GridColumn>
        </Grid>
      </PanelSection>

      <PanelSection borderless padding="sMedium sMedium no">
        <PanelSectionTitle>Contact information</PanelSectionTitle>
        <Grid multiLine>
          <GridColumn size="4/12" padding="no small no no">
            <Field name="phoneNumber" label="Phone" component={Input} />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field name="faxNumber" label="Fax" component={Input} />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <StateSelect
              name="state"
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="county"
              label="County"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="city"
              label="City"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="address"
              label="Address"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="zipCode"
              label="Zip Code"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="latitude"
              label="Latitude"
              component={Input}
              disabled={!isAdmin}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="longitude"
              label="Longitude"
              component={Input}
              disabled={!isAdmin}
            />
          </GridColumn>
        </Grid>
      </PanelSection>

      <PanelSection borderless padding="sMedium sMedium no">
        <PanelSectionTitle>Other information</PanelSectionTitle>
        <Grid multiLine>
          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="licensee"
              label="Licensee"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="licenseCode"
              label="License Code"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="capacity"
              label="Capacity"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="licenseEffective"
              label="License Effective"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no small no no">
            <Field
              name="licenseExpiration"
              label="License Expiration"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no xxSmall no no">
            <Field
              name="isMedicaidCertified"
              label="Medicaid Certified"
              component={Checkbox}
            />
          </GridColumn>

          <GridColumn size="8/12" padding="no small no no">
            <Field
              name="supplementaryServicesProvided"
              label="Supplimentary Services"
              component={Input}
              disabled={!(isAdmin && isFullyManagedByAdmin)}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no xxSmall no no">
            <Field
              name="isFeatured"
              label="Featured"
              component={Checkbox}
              disabled={!isAdmin}
            />
          </GridColumn>
        </Grid>
      </PanelSection>

      <PanelSection>
        <PanelSectionTitle>Description</PanelSectionTitle>
        <Field
          name="description"
          label="Description"
          component={RichTextInput}
        />
      </PanelSection>

      <PanelFooter>
        <PanelActions>
          <Button type="submit" color="primary" disabled={!dirty}>
            {isSavingProvider ? 'Saving' : 'Save'}
          </Button>
        </PanelActions>
      </PanelFooter>
    </form>
  </Panel>
)

ProviderEditorForm.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isFullyManagedByAdmin: PropTypes.bool.isRequired,
  isNewProvider: PropTypes.bool,
  isSavingProvider: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

ProviderEditorForm.defaultProps = {
  isNewProvider: false,
}

export default ProviderEditorForm
