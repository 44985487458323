import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { loadingOverlay } from '../../styles'
import { sizeMapper } from '../../../utils/styles'

export const PanelSectionTitle = styled.h3`
  margin: 0 0 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
`

export const PanelSection = styled.div`
  padding: ${props => sizeMapper(props.padding, 'sMedium')};
  border-bottom: 1px solid ${props => props.theme.grayLight};

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.borderless &&
    css`
      border-bottom: none;
    `}
`

export const PanelTitle = styled.h2`
  margin: 0;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 24px;
`

export const PanelActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const PanelFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 25px 20px 20px 20px;
`


export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 20px 0 20px;
`

export const Panel = styled.div`
  position: relative;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  background-color: #fff;
  box-shadow: 0 2px 8px
    ${props => transparentize(0.9, props.theme.brandDefault)};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')}
    `}
`
