import update from 'immutability-helper'
import { claimProvider as doClaimProvider } from '../services'

// Actions
const START_CLAIM = 'claim/START_CLAIM'
const COMPLETE_CLAIM = 'claim/COMPLETE_CLAIM'
const FAIL_CLAIM = 'claim/FAIL_CLAIM'
const RESET = 'claim/RESET'

// Initial state
const initialState = {
  isClaiming: false,
  isClaimFailed: false,
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_CLAIM:
      return update(state, {
        $merge: { isClaiming: true, isClaimFailed: false },
      })

    case COMPLETE_CLAIM: {
      return update(state, {
        isClaiming: { $set: false },
      })
    }

    case FAIL_CLAIM:
      return update(state, {
        $merge: { isClaiming: false, isClaimFailed: true },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startProviderClaim = () => ({ type: START_CLAIM })
const completeProviderClaim = () => ({ type: COMPLETE_CLAIM })
const failProviderClaim = () => ({ type: FAIL_CLAIM })

export const claimProvider = providerId => dispatch => {
  dispatch(startProviderClaim())
  const providerClaimPromise = doClaimProvider(providerId)

  providerClaimPromise
    .then(() => dispatch(completeProviderClaim()))
    .catch(() => dispatch(failProviderClaim()))

  return providerClaimPromise
}

export const resetProviderClaim = () => ({ type: RESET })
