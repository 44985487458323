import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Button, ButtonSet } from '../../../core/components/styled'
import { Input } from '../../../core/components'

const ForgotPasswordForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field name="email" label="Email" component={Input} />

    <ButtonSet margin="medium no small">
      <Button type="submit" fluid color="primary" margin="no no xSmall">
        Reset password
      </Button>
    </ButtonSet>
  </form>
)

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default ForgotPasswordForm
