import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { pulse, COLOR_DEFAULT } from '../../styles'
import { sizeMapper, colorMapper } from '../../../utils/styles'

const getMessageBackgroundColor = props =>
  props.color === COLOR_DEFAULT || props.color === undefined
    ? 'transparent'
    : transparentize(0.9, colorMapper(props))

const Message = styled.div`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'xxSmall xSmall')};
  background-color: ${getMessageBackgroundColor};
  border-radius: 6px;
  line-height: 22px;
  color: ${colorMapper};

  ${props =>
    props.animated &&
    css`
      animation: ${pulse} 0.3s ease-in-out 2;
    `};
`

export default Message
