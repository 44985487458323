import React from 'react'
import PropTypes from 'prop-types'
import {
  TablePagination as TablePaginationContainer,
  TablePaginationSection,
  TablePaginationInput,
  TablePaginationSelect,
  TablePaginationButton,
  TablePaginationButtonIcon,
  Text,
} from './styled'

const TablePagination = ({
  pageSize,
  pageIndex,
  pageCount,
  pageOptions,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  totalCount,
}) => {
  const onPageIndexInputChange = event => {
    const pageIndex = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(pageIndex)
  }

  const onPageCountSelectChange = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <TablePaginationContainer>
      <TablePaginationSection>
        <TablePaginationButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <TablePaginationButtonIcon icon="backward" />
        </TablePaginationButton>

        <TablePaginationButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <TablePaginationButtonIcon icon="back" />
        </TablePaginationButton>

        <TablePaginationButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <TablePaginationButtonIcon icon="next" />
        </TablePaginationButton>

        <TablePaginationButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <TablePaginationButtonIcon icon="forward" />
        </TablePaginationButton>

        <Text block margin="no small">
          Go to page:
        </Text>

        <TablePaginationInput
          type="number"
          value={pageIndex + 1}
          onChange={onPageIndexInputChange}
        />
      </TablePaginationSection>

      <TablePaginationSection>
        {totalCount} results returned.
      </TablePaginationSection>

      <TablePaginationSection>
        <Text margin="no xxSmall no no">Page</Text>
        <Text weight="medium" margin="no small no no">
          {pageIndex + 1} of {pageOptions.length}
        </Text>

        <TablePaginationSelect
          value={pageSize}
          onChange={onPageCountSelectChange}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </TablePaginationSelect>
      </TablePaginationSection>
    </TablePaginationContainer>
  )
}

TablePagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageOptions: PropTypes.array.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
}

export default TablePagination
