import styled from 'styled-components'
import { sizeMapper } from '../../../utils/styles'
import Icon from '../Icon'

export const DropzoneIcon = styled(Icon).attrs({ icon: 'add' })`
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  color: ${props => props.theme.brandPrimary};
`

export const DropzoneDescription = styled.span`
  font-size: 16px;
`

export const DropzoneFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

export const Dropzone = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  margin: ${props => sizeMapper(props.margin, 'no')};
  border: 2px dashed ${props => props.theme.grayDark};
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.brandPrimary};
  }
`
