import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTable } from 'react-table'
import {
  Panel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Message,
} from '../../../../core/components/styled'

const ClaimsTable = ({ columns, data, isLoading, fetchData }) => {
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <Panel isLoading={isLoading}>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {rows.map(
            row =>
              prepareRow(row) || (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>

      {!data.length && <Message padding="small">No claims requests.</Message>}
    </Panel>
  )
}

ClaimsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
}

export default ClaimsTable
