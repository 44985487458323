import * as Yup from 'yup'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please povide a valid email address.')
    .required('Please provide your email address.'),

  password: Yup.string().required('Please provide your password.'),
})

export default loginSchema
