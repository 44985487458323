import IdTokenVerifier from 'idtoken-verifier'
import { http } from '../../core/services'
import { createSession, getSessionValue, destroySession } from './session'

export const getUser = () => getSessionValue('user')
export const getAccessToken = () => getSessionValue('accessToken')

export const register = async data => http.post('/users/register', data)

export const login = async (username, password) => {
  const response = await http.post('users/login', { username, password })
  const { accessToken } = response.data

  const idTokenVerifier = new IdTokenVerifier()
  const user = idTokenVerifier.decode(accessToken).payload
  createSession(user, accessToken)
  return user
}

export const logout = () => {
  destroySession()
}
