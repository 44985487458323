/* global google */
import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from 'react-google-maps'
import mapPin from '../assets/img/mapPin.svg'

const MapMarker = ({ coordinates }) => {
  const markerPosition = {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  }

  return (
    <Marker
      icon={{ url: mapPin, scaledSize: new google.maps.Size(48, 48) }}
      position={markerPosition}
      noRedraw
    />
  )
}

MapMarker.propTypes = {
  coordinates: PropTypes.object.isRequired,
}

export default MapMarker
