import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Button, ButtonSet } from '../../../core/components/styled'
import { Input } from '../../../core/components'

const ResetPasswordForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field name="password" label="Password" type="password" component={Input} />
    <Field
      name="confirmedPassword"
      label="Confirm password"
      type="password"
      component={Input}
    />

    <ButtonSet margin="medium no small">
      <Button type="submit" fluid color="primary" margin="no no xSmall">
        Reset password
      </Button>
    </ButtonSet>
  </form>
)

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default ResetPasswordForm
