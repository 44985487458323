import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { pickBy, keys, map } from 'lodash-es'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import {
  createSuccessNotification,
  createErrorNotification,
} from '../../../core/ducks'
import { Button } from '../../../core/components/styled'
import {
  PageContent,
  PageHeader,
  PageHeaderActions,
  PageTitle,
} from '../../../common/components/styled'
import { isAdmin } from '../../../account/ducks'
import { getAmenitiesFormInitialValues } from '../../helpers'
import {
  saveProvider,
  publishProvider,
  saveAmenities,
  moveImage,
  saveImageRanks,
  saveImageDescription,
  setHeroImage,
  sortImagesByRank,
  uploadImages,
  deleteImage,
  saveNotes,
  hasUnpublishedChanges,
} from '../../ducks'
import { providerSchema } from '../../schemas'
import {
  ProviderEditorForm,
  AmenitiesEditorForm,
  NotesEditorForm,
} from '../forms'
import { ProviderMap, ImagesEditor, ProviderPublishToolbar } from './sections'
import { getFrontendBaseURL } from '../../../core/services/environment'

const ProviderEditorPage = ({
  match,
  isAdmin,
  hasUnpublishedChanges,
  existsUnapprovedPublishedContent,
  provider,
  isSavingProvider,
  isPublishingProvider,
  amenities,
  isSavingAmenities,
  images,
  isUploadingImages,
  notes,
  isSavingNotes,
  createSuccessNotification,
  createErrorNotification,
  saveProvider,
  publishProvider,
  saveAmenities,
  moveImage,
  saveImageRanks,
  saveImageDescription,
  setHeroImage,
  uploadImages,
  deleteImage,
  saveNotes,
}) => {
  const { providerId } = match.params

  const amenitiesFormInitialValues = useMemo(
    () => getAmenitiesFormInitialValues(amenities),
    [amenities],
  )

  const notesFormInitialValues = useMemo(() => ({ notes }), [notes])

  const handleProviderEditorFormSubmit = useCallback(
    async (formData, { resetForm }) => {
      try {
        resetForm(formData)
        await saveProvider(providerId, formData)
        createSuccessNotification('The provider details were saved.')
      } catch (error) {
        createErrorNotification('Saving provider details failed.')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      saveProvider,
    ],
  )

  const handleProviderPublish = useCallback(async () => {
    try {
      await publishProvider(providerId)
      createSuccessNotification('The provider details were published.')
    } catch (error) {
      createErrorNotification('Publishing provider details failed.')
    }
  }, [
    providerId,
    createSuccessNotification,
    createErrorNotification,
    publishProvider,
  ])

  const handleAmenitiesFormSubmit = useCallback(
    async (formData, { resetForm }) => {
      try {
        resetForm(formData)
        const amenityIds = map(keys(pickBy(formData.statuses)), Number)
        await saveAmenities(providerId, amenityIds)
        createSuccessNotification('The amenities were saved.')
      } catch (error) {
        createErrorNotification('Saving amenities failed.')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      saveAmenities,
    ],
  )

  const handleNotesFormSubmit = useCallback(
    async (formData, { resetForm }) => {
      try {
        resetForm(formData)
        await saveNotes(providerId, formData.notes)
        createSuccessNotification('The notes were saved.')
      } catch (error) {
        createErrorNotification('Saving notes failed.')
      }
    },
    [providerId, createSuccessNotification, createErrorNotification, saveNotes],
  )

  const handleImageReorder = useCallback(
    async ({ oldIndex, newIndex }) => {
      try {
        moveImage(oldIndex, newIndex)
        await saveImageRanks(providerId)
        createSuccessNotification('The image order was saved.')
      } catch (error) {
        createErrorNotification('Saving image order failed.')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      moveImage,
      saveImageRanks,
    ],
  )

  const handleImageUpload = useCallback(
    async files => {
      try {
        await uploadImages(providerId, files)
        createSuccessNotification('The images were uploaded.')
      } catch (error) {
        createErrorNotification('Uploading images failed..')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      uploadImages,
    ],
  )

  const handleImageDecriptionChange = useCallback(
    async (id, description) => {
      try {
        await saveImageDescription(id, providerId, description)
        createSuccessNotification('The image description was saved.')
      } catch (error) {
        createErrorNotification('Saving image description failed.')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      saveImageDescription,
    ],
  )

  const handleImageIsHeroChange = useCallback(
    async id => {
      try {
        await setHeroImage(id, providerId)
        createSuccessNotification('Hero image was set.')
      } catch (error) {
        createErrorNotification('Setting hero image failed.')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      setHeroImage,
    ],
  )

  const handleImageDeletion = useCallback(
    async imageId => {
      try {
        if (!window.confirm('Are you sure?')) return
        await deleteImage(imageId, providerId)
        createSuccessNotification('The image was deleted.')
      } catch (error) {
        createErrorNotification('Deleting image failed.')
      }
    },
    [
      providerId,
      createSuccessNotification,
      createErrorNotification,
      deleteImage,
    ],
  )

  const renderProviderEditorForm = useCallback(
    formikProps => (
      <ProviderEditorForm
        isAdmin={isAdmin}
        isFullyManagedByAdmin={provider.isFullyManagedByAdmin}
        isSavingProvider={isSavingProvider}
        {...formikProps}
      />
    ),
    [isAdmin, isSavingProvider, provider.isFullyManagedByAdmin],
  )

  const renderAmenitiesForm = useCallback(
    formikProps => (
      <AmenitiesEditorForm
        amenities={amenities}
        isSavingAmenities={isSavingAmenities}
        {...formikProps}
      />
    ),
    [amenities, isSavingAmenities],
  )

  const renderNotesForm = useCallback(
    formikProps => (
      <NotesEditorForm isSavingNotes={isSavingNotes} {...formikProps} />
    ),
    [isSavingNotes],
  )

  return (
    <PageContent compact>
      <PageHeader>
        <PageTitle>
          {provider.name}{' '}
          {existsUnapprovedPublishedContent &&
            ' - has content undergoing publishing approval.'}
        </PageTitle>
        <PageHeaderActions>
          <Button
            as="a"
            href={`${getFrontendBaseURL()}/communities/${providerId}/preview?preview=true`}
            target="blank"
            color="primary"
            line
          >
            Preview
          </Button>
        </PageHeaderActions>
      </PageHeader>

      {isAdmin && (
        <Formik
          render={renderNotesForm}
          initialValues={notesFormInitialValues}
          onSubmit={handleNotesFormSubmit}
        />
      )}
      {provider.latitude && provider.longitude && (
        <ProviderMap
          latitude={provider.latitude}
          longitude={provider.longitude}
        />
      )}

      <Formik
        render={renderProviderEditorForm}
        initialValues={provider}
        validationSchema={providerSchema}
        onSubmit={handleProviderEditorFormSubmit}
      />

      <Formik
        render={renderAmenitiesForm}
        initialValues={amenitiesFormInitialValues}
        onSubmit={handleAmenitiesFormSubmit}
      />

      <ImagesEditor
        images={images}
        isUploadingImages={isUploadingImages}
        handleImageReorder={handleImageReorder}
        handleImageDescriptionChange={handleImageDecriptionChange}
        handleImageIsHeroChange={handleImageIsHeroChange}
        handleImageUpload={handleImageUpload}
        handleImageDeletion={handleImageDeletion}
      />

      <ProviderPublishToolbar
        providerId={providerId}
        isPublishingProvider={isPublishingProvider}
        display={hasUnpublishedChanges}
        existsUnapprovedPublishedContent={existsUnapprovedPublishedContent}
        handleProviderPublish={handleProviderPublish}
      />
    </PageContent>
  )
}

ProviderEditorPage.propTypes = {
  match: RouterPropTypes.match.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  hasUnpublishedChanges: PropTypes.bool.isRequired,
  existsUnapprovedPublishedContent: PropTypes.bool.isRequired,
  provider: PropTypes.object.isRequired,
  isSavingProvider: PropTypes.bool.isRequired,
  isPublishingProvider: PropTypes.bool.isRequired,
  amenities: PropTypes.array.isRequired,
  isSavingAmenities: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  isUploadingImages: PropTypes.bool.isRequired,
  notes: PropTypes.string.isRequired,
  isSavingNotes: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  saveProvider: PropTypes.func.isRequired,
  publishProvider: PropTypes.func.isRequired,
  saveAmenities: PropTypes.func.isRequired,
  moveImage: PropTypes.func.isRequired,
  saveImageRanks: PropTypes.func.isRequired,
  saveImageDescription: PropTypes.func.isRequired,
  setHeroImage: PropTypes.func.isRequired,
  uploadImages: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  saveNotes: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state.account.login.user),
  hasUnpublishedChanges: hasUnpublishedChanges(
    state.providers.provider.checksum,
  ),
  existsUnapprovedPublishedContent:
    state.providers.provider.checksum.existsUnapprovedPublishedContent,
  provider: state.providers.provider.provider,
  isSavingProvider: state.providers.provider.isSaving,
  isPublishingProvider: state.providers.provider.isPublishing,
  amenities: state.providers.amenities.amenities,
  isSavingAmenities: state.providers.amenities.isSaving,
  images: sortImagesByRank(state.providers.images.images),
  isUploadingImages: state.providers.images.isUploading,
  notes: state.providers.notes.notes,
  isSavingNotes: state.providers.notes.isSaving,
})

const mapDispatchToProps = {
  createSuccessNotification,
  createErrorNotification,
  saveProvider,
  publishProvider,
  saveAmenities,
  moveImage,
  saveImageRanks,
  saveImageDescription,
  setHeroImage,
  uploadImages,
  deleteImage,
  saveNotes,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProviderEditorPage),
)
