import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createErrorNotification } from '../../../core/ducks'
import {
  PageContent,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import {
  loadUnapprovedPublications,
  approvePublication,
  resetPublications,
} from '../../ducks'
import { PublicationsTable, PublicationsTableActions } from './sections'
import { getFrontendBaseURL } from '../../../core/services/environment'

const getColumns = handlePublicationApproval => [
  { Header: '#', accessor: 'providerId' },
  {
    Header: 'Name',
    Cell: cell => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${getFrontendBaseURL()}/communities/${
          cell.row.original.providerId
        }/preview?preview=true`}
      >
        {cell.row.original.providerName}
      </a>
    ),
  },
  { Header: 'Address', accessor: 'providerAddress.formattedAddress' },
  { Header: 'Phone', accessor: 'providerPublisher.phoneNumber' },
  { Header: 'Username', accessor: 'providerPublisher.userName' },
  {
    Header: 'Actions',
    Cell: props => (
      <PublicationsTableActions
        handlePublicationApproval={handlePublicationApproval}
        {...props}
      />
    ),
  },
]

const PublicationsPage = ({
  publications,
  isLoading,
  createErrorNotification,
  loadUnapprovedPublications,
  approvePublication,
  resetPublications,
}) => {
  const handlePublicationApproval = useCallback(
    id =>
      window.confirm('Are you sure?') &&
      approvePublication(id).catch(() =>
        createErrorNotification('Publication approval failed.'),
      ),
    [approvePublication, createErrorNotification],
  )

  const columns = useMemo(() => getColumns(handlePublicationApproval), [
    handlePublicationApproval,
  ])

  useEffect(() => resetPublications, [resetPublications])

  return (
    <>
      <DocumentTitle>Publications</DocumentTitle>
      <PageContent>
        <PageHeader>
          <PageTitle>Publications</PageTitle>
        </PageHeader>

        <PublicationsTable
          columns={columns}
          data={publications}
          fetchData={loadUnapprovedPublications}
          isLoading={isLoading}
        />
      </PageContent>
    </>
  )
}

PublicationsPage.propTypes = {
  publications: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  loadUnapprovedPublications: PropTypes.func.isRequired,
  approvePublication: PropTypes.func.isRequired,
  resetPublications: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  publications: state.moderation.publications.publications,
  isLoading: state.moderation.publications.isLoading,
})

const mapDispatchToProps = {
  createErrorNotification,
  loadUnapprovedPublications,
  approvePublication,
  resetPublications,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicationsPage)
