import * as Yup from 'yup'
import { VALIDATION_REGEXES } from '../../utils/constants'

const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, 'Password must be min 6 chars long.')
    .matches(
      VALIDATION_REGEXES.PASSWORD,
      'Password must contain at least one lowercase character, one uppercase character, one digit and one special character out of the following list #?!@$%^&*-.].',
    )
    .required('Please choose a password.'),

  password: Yup.string()
    .min(6, 'Password must be min 6 chars long.')
    .matches(
      VALIDATION_REGEXES.PASSWORD,
      'Password must contain at least one lowercase character, one uppercase character, one digit and one special character out of the following list #?!@$%^&*-.].',
    )
    .required('Please choose a password.'),

  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match.')
    .required('Please confirm your password.'),
})

export default changePasswordSchema
