import styled, { css } from 'styled-components'
import { mapper, sizeMapper, colorMapper } from '../../../utils/styles'

const Text = styled.span`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${props =>
        mapper(props.weight, {
          light: props.theme.fontWeightLight,
          normal: props.theme.fontWeightNormal,
          medium: props.theme.fontWeightMedium,
        })};
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
`

export default Text
