import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  TableActions,
  TableAction,
  TableActionIcon,
} from '../../../../core/components/styled'

const ProviderSearchTableActions = ({ row }) => (
  <TableActions>
    <TableAction as={Link} to={`/providers/${row.original.id}`} target="_blank">
      <TableActionIcon icon="edit" />
    </TableAction>
  </TableActions>
)

ProviderSearchTableActions.propTypes = {
  row: PropTypes.object.isRequired,
}

export default ProviderSearchTableActions
