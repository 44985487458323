const PROVIDER_CLAIM_URL_KEY = 'providerClaimUrl'

export const setProviderClaimUrl = url => {
  const regex = new RegExp('^/providers/[0-9]+/claim$')
  if (regex.test(url)) localStorage.setItem(PROVIDER_CLAIM_URL_KEY, url)
}
export const getProviderClaimUrl = () =>
  localStorage.getItem(PROVIDER_CLAIM_URL_KEY)
export const removeProviderClaimUrl = () =>
  localStorage.removeItem(PROVIDER_CLAIM_URL_KEY)
