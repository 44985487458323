import React from 'react'
import PropTypes from 'prop-types'
import { StatusLabel } from '../../../../core/components/styled'

const ProvidersTableStatus = ({ row }) =>
  row.original.isProviderClaimApproved ? (
    <StatusLabel color="success">Approved</StatusLabel>
  ) : (
    <StatusLabel color="warning">Pending</StatusLabel>
  )

ProvidersTableStatus.propTypes = {
  row: PropTypes.object.isRequired,
}

export default ProvidersTableStatus
