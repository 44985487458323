import update from 'immutability-helper'
import { loadProviders as doLoadProviders } from '../services'

// Actions
const START_LOAD = 'providers/providers/START_LOAD'
const COMPLETE_LOAD = 'providers/providers/COMPLETE_LOAD'
const FAIL_LOAD = 'providers/providers/FAIL_LOAD'
const RESET = 'providers/providers/RESET'

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  providers: [],
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true, isLoadFailed: false },
      })

    case COMPLETE_LOAD:
      return update(state, {
        $merge: { isLoading: false, providers: action.providers },
      })

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false, isLoadFailed: true },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startLoad = () => ({ type: START_LOAD })
const completeLoad = providers => ({ type: COMPLETE_LOAD, providers })
const failLoad = () => ({ type: FAIL_LOAD })

export const loadProviders = () => dispatch => {
  dispatch(startLoad())
  const loadPromise = doLoadProviders()

  loadPromise
    .then(providers => dispatch(completeLoad(providers)))
    .catch(() => dispatch(failLoad()))

  return loadPromise
}

export const resetProviders = () => ({ type: RESET })
