import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Button } from '../../../core/components/styled'
import { ButtonLink } from '../../../core/components'
import {
  PageContent,
  PageHeader,
  PageHeaderActions,
  PageTitle,
} from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { getProviderFiltersFormInitialValues } from '../../helpers'
import { searchProviders, resetProviderSearch } from '../../ducks'
import {
  ProviderSearchTable,
  ProviderSearchTableNotes,
  ProviderSearchTableActions,
} from './sections'
import { ProviderFiltersForm } from '../forms'

const getColumns = () => [
  { Header: '#', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Address', accessor: 'fullAddress' },
  { Header: 'Phone', accessor: 'phoneNumber' },
  { Header: 'Citations', accessor: 'totalCitations' },
  { Header: 'Distance', accessor: 'distance' },
  { Header: 'Notes', Cell: ProviderSearchTableNotes },
  { Header: 'Actions', Cell: ProviderSearchTableActions },
]

const normalizeFormData = formData => {
  const [centerLat, centerLong] = formData.centerPoint.split(',')
  return { ...formData, centerLat, centerLong }
}

const ProviderSearchPage = ({
  providers,
  pageCount,
  totalCount,
  isSearching,
  searchProviders,
  resetProviderSearch,
}) => {
  const [filters, setFilters] = useState({})
  const initialValues = useMemo(() => getProviderFiltersFormInitialValues(), [])
  const columns = useMemo(getColumns, [])

  const handleSubmit = useCallback(
    formData => setFilters(normalizeFormData(formData)),
    [],
  )

  const renderForm = useCallback(
    formikProps => <ProviderFiltersForm {...formikProps} />,
    [],
  )

  useEffect(() => resetProviderSearch, [resetProviderSearch])

  return (
    <>
      <DocumentTitle>Provider Search</DocumentTitle>
      <PageContent>
        <PageHeader>
          <PageTitle>Provider Search</PageTitle>
          <PageHeaderActions>
            <Button as={ButtonLink} to="/providers/create" line color="primary">
              Create provider
            </Button>
          </PageHeaderActions>
        </PageHeader>

        <Formik
          render={renderForm}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />

        <ProviderSearchTable
          columns={columns}
          filters={filters}
          data={providers}
          fetchData={searchProviders}
          isLoading={isSearching}
          pageCount={pageCount}
          totalCount={totalCount}
        />
      </PageContent>
    </>
  )
}

ProviderSearchPage.propTypes = {
  providers: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isSearching: PropTypes.bool.isRequired,
  searchProviders: PropTypes.func.isRequired,
  resetProviderSearch: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  providers: state.providers.providerSearch.providers,
  pageCount: state.providers.providerSearch.pageCount,
  totalCount: state.providers.providerSearch.count,
  isSearching: state.providers.providerSearch.isSearching,
})

const mapDispatchToProps = { searchProviders, resetProviderSearch }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderSearchPage)
