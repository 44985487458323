import styled from 'styled-components'
import { transparentize } from 'polished'
import { fadeAndSlideInUp } from '../../styles'
import { BareButton } from './Button'
import { colorMapper } from '../../../utils/styles'
import Icon from '../Icon'

export const NotificationCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 12px;
  height: 12px;
  margin-top: -2px;
  vertical-align: middle;
`

export const NotificationClose = styled(BareButton).attrs({ type: 'button' })`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 14px;
  height: 14px;
`

export const Notification = styled.div`
  position: relative;
  width: 330px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid ${colorMapper};
  box-shadow: 0 6px 16px ${transparentize(0.8, '#000')};
  line-height: 20px;
  font-size: 15px;
  color: ${colorMapper};
  animation: 0.3s ease-out ${fadeAndSlideInUp};
`

export const NotificationSet = styled.div`
  position: fixed;
  top: 16px;
  right: 10px;
  z-index: 1;
`
