import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ButtonLink = ({ to, className, children }) => (
  <Link to={to} className={className}>
    {children}
  </Link>
)

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ButtonLink
