import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { resetAccountPassword, resetAccount } from '../../ducks'
import { createSuccessNotification } from '../../../core/ducks'
import { Message } from '../../../core/components/styled'
import { Page } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { resetPasswordSchema } from '../../schemas'
import { AccountPanel, AccountPanelTitle } from '../styled'
import { ResetPasswordForm } from '../forms'

const ResetPasswordPage = ({
  isResetPassword,
  isResettingPassword,
  isResetPasswordFailed,
  createSuccessNotification,
  resetAccountPassword,
  resetAccount,
  location,
}) => {
  const handleSubmit = useCallback(
    data => {
      resetAccountPassword(data, location.search)
        .then(() =>
          createSuccessNotification(
            'Your password was reset successfully.',
            false,
          ),
        )
        .catch(() => {})
    },
    [createSuccessNotification, resetAccountPassword, location.search],
  )

  useEffect(() => resetAccount, [resetAccount])

  return (
    <>
      <DocumentTitle>Reset your password</DocumentTitle>
      <Page>
        <AccountPanel isLoading={isResettingPassword}>
          <AccountPanelTitle>Reset your password</AccountPanelTitle>

          <Formik
            render={ResetPasswordForm}
            initialValues={{ password: '', confirmedPassword: '' }}
            validationSchema={resetPasswordSchema}
            onSubmit={handleSubmit}
          />

          {isResetPasswordFailed && (
            <Message animated margin="medium no no" color="alert">
              Could not reset your password.
            </Message>
          )}

          {isResetPassword && <Redirect to={{ pathname: '/' }} />}
        </AccountPanel>
      </Page>
    </>
  )
}

ResetPasswordPage.propTypes = {
  isResetPassword: PropTypes.bool.isRequired,
  isResettingPassword: PropTypes.bool.isRequired,
  isResetPasswordFailed: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  resetAccountPassword: PropTypes.func.isRequired,
  resetAccount: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
}

const mapStateToProps = state => ({
  isResetPassword: state.account.account.isResetPassword,
  isResettingPassword: state.account.account.isResettingPassword,
  isResetPasswordFailed: state.account.account.isResetPasswordFailed,
})

const mapDispatchToProps = {
  createSuccessNotification,
  resetAccountPassword,
  resetAccount,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordPage)
