import styled from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'react-router-dom'
import { Icon } from '../../../core/components'
import { mapper } from '../../../utils/styles'

export const NavigationBarDropdownItem = styled(Link)`
  display: block;
  padding: 12px 15px;
  color: ${props => props.theme.grayBase};

  &:hover {
    background-color: ${props => props.theme.grayLight};
  }
`

export const NavigationBarDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: ${props => mapper(props.align, { left: 0, right: 'auto' }, 'left')};
  right: ${props => mapper(props.align, { right: 0, left: 'auto' }, 'left')};
  z-index: 1;
  display: none;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 0 8px ${props => transparentize(0.9, props.theme.grayBase)};
`

export const NavigationBarItemIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin: 20px 20px 20px 0;
`

export const NavigationBarItemText = styled.span`
  display: inline-block;
  padding: 0 20px;
  border-bottom: 2px solid transparent;
  line-height: 60px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 15px;
  color: ${props => props.theme.brandDefault};

  &.active {
    border-bottom-color: ${props => props.theme.brandPrimary};
    color: ${props => props.theme.brandPrimary};
  }
`

export const NavigationBarItem = styled.div`
  display: inline-flex;
  position: relative;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.grayLight};

    ${NavigationBarDropdown} {
      display: block;
    }
  }
`

export const NavigationBarItems = styled.div`
  display: flex;
`

export const NavigationBar = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 4px 8px
    ${props => transparentize(0.9, props.theme.brandDefault)};
`
