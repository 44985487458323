import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

export const ProviderPublishToolbarDescription = styled.span`
  font-size: 16px;
`

export const ProviderPublishToolbarActions = styled.div`
  display: flex;
`

export const ProviderPublishToolbar = styled.div`
  position: fixed;
  bottom: 4px;
  left: calc((100vw - 1200px) / 2);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 70px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 16px ${props => transparentize(0.8, props.theme.grayBase)};
  transform: translateY(80px);
  transition: transform 0.3s ease-out;

  ${props =>
    props.isVisible &&
    css`
      transform: none;
    `};
`
