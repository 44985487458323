import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'
import { ImageSet as ImageSetContainer } from '../../../../core/components/styled'
import ImageSetItem from './ImageSetItem'

const ImageSet = ({
  images,
  handleImageDescriptionChange,
  handleImageIsHeroChange,
  handleImageDeletion,
}) => (
  <ImageSetContainer>
    {images.map((image, index) => (
      <ImageSetItem
        key={image.id}
        index={index}
        image={image}
        handleImageDescriptionChange={handleImageDescriptionChange}
        handleImageIsHeroChange={handleImageIsHeroChange}
        handleImageDeletion={handleImageDeletion}
      />
    ))}
  </ImageSetContainer>
)

ImageSet.propTypes = {
  images: PropTypes.array.isRequired,
  handleImageDescriptionChange: PropTypes.func.isRequired,
  handleImageIsHeroChange: PropTypes.func.isRequired,
  handleImageDeletion: PropTypes.func.isRequired,
}

export default SortableContainer(ImageSet)
