import update from 'immutability-helper'
import {
  loadAmenities as doLoadAmenities,
  updateAmenities as doUpdateAmenities,
} from '../services'
import { updateCurrentProviderChecksum } from './provider'

// Actions
const START_LOAD = 'providers/amenities/START_LOAD'
const COMPLETE_LOAD = 'providers/amenities/COMPLETE_LOAD'
const FAIL_LOAD = 'providers/amenities/FAIL_LOAD'
const START_SAVE = 'providers/amenities/START_SAVE'
const COMPLETE_SAVE = 'providers/amenities/COMPLETE_SAVE'
const FAIL_SAVE = 'providers/amenities/FAIL_SAVE'
const RESET = 'providers/amenities/RESET'

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  isSaving: false,
  isSaveFailed: false,
  amenities: [],
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true, isLoadFailed: false },
      })

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          count: action.count,
          amenities: action.amenities,
        },
      })

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false, isLoadFailed: true },
      })

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true, isSaveFailed: false },
      })

    case COMPLETE_SAVE:
      return update(state, { $merge: { isSaving: false } })

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startLoad = () => ({ type: START_LOAD })
const completeLoad = amenities => ({ type: COMPLETE_LOAD, amenities })
const failLoad = () => ({ type: FAIL_LOAD })
const startSave = () => ({ type: START_SAVE })
const completeSave = () => ({ type: COMPLETE_SAVE })
const failSave = () => ({ type: FAIL_SAVE })

export const loadAmenities = providerId => dispatch => {
  dispatch(startLoad())
  const loadPromise = doLoadAmenities(providerId)

  loadPromise
    .then(amenities => dispatch(completeLoad(amenities)))
    .catch(() => dispatch(failLoad()))

  return loadPromise
}

export const saveAmenities = (providerId, amenityIds) => dispatch => {
  dispatch(startSave())
  const savePromise = doUpdateAmenities(providerId, amenityIds)

  savePromise
    .then(({ checksum }) => {
      dispatch(completeSave())
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failSave()))

  return savePromise
}

export const resetAmenities = () => ({ type: RESET })
