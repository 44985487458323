import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { logout } from '../../ducks'

const LogoutPage = ({ logout }) => {
  logout()
  return <Redirect to={{ pathname: '/account/login' }} />
}

LogoutPage.propTypes = {
  logout: PropTypes.func.isRequired,
}

const mapDispatchToProps = { logout }

export default connect(
  undefined,
  mapDispatchToProps,
)(LogoutPage)
