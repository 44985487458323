import React from 'react'

const containerElement = <div style={{ height: '100%' }} />
const mapElement = <div style={{ height: '100%' }} />

const googleMap = Component => props => (
  <Component
    {...props}
    containerElement={containerElement}
    mapElement={mapElement}
  />
)

export default googleMap
