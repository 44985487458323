import update from 'immutability-helper'
import memoize from 'memoize-one'
import arrayMove from 'array-move'
import { sortBy, mapKeys, mapValues, findIndex } from 'lodash-es'
import {
  loadImages as doLoadImages,
  saveImageRanks as doSaveImageRanks,
  saveImageDescription as doSaveImageDescription,
  setHeroImage as doSetHeroImage,
  uploadImages as doUploadImages,
  deleteImage as doDeleteImage,
} from '../services'
import { updateCurrentProviderChecksum } from './provider'

// Actions
const START_LOAD = 'providers/images/START_LOAD'
const COMPLETE_LOAD = 'providers/images/COMPLETE_LOAD'
const FAIL_LOAD = 'providers/images/FAIL_LOAD'
const MOVE = 'providers/images/MOVE'
const START_SAVE_RANKS = 'providers/images/START_SAVE_RANKS'
const COMPLETE_SAVE_RANKS = 'providers/images/COMPLETE_SAVE_RANKS'
const FAIL_SAVE_RANKS = 'providers/images/FAIL_SAVE_RANKS'
const START_SAVE_DESCRIPTION = 'providers/images/START_SAVE_DESCRIPTION'
const COMPLETE_SAVE_DESCRIPTION = 'providers/images/COMPLETE_SAVE_DESCRIPTION'
const FAIL_SAVE_DESCRIPTION = 'providers/images/FAIL_SAVE_DESCRIPTION'
const START_SET_HERO = 'providers/images/START_SET_HERO'
const COMPLETE_SET_HERO = 'providers/images/COMPLETE_SET_HERO'
const FAIL_SET_HERO = 'providers/images/FAIL_SET_HERO'
const START_UPLOAD = 'providers/images/START_UPLOAD'
const COMPLETE_UPLOAD = 'providers/images/COMPLETE_UPLOAD'
const FAIL_UPLOAD = 'providers/images/FAIL_UPLOAD'
const START_DELETE = 'providers/images/START_DELETE'
const COMPLETE_DELETE = 'providers/images/COMPLETE_DELETE'
const FAIL_DELETE = 'providers/images/FAIL_DELETE'
const RESET = 'providers/images/RESET'

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  isSavingRanks: false,
  isSaveRanksFailed: false,
  isSavingDescription: false,
  isSaveDescriptionFailed: false,
  isSettingHero: false,
  isSetHeroFailed: false,
  isUploading: false,
  isUploadFailed: false,
  isDeleting: false,
  isDeleteFailed: false,
  images: [],
}

// Helpers
const recalculateImageRanks = images =>
  images.map((image, index) => ({ ...image, rank: index + 1 }))

const setImageAsHero = (images, id) =>
  images.map(image => ({ ...image, isHero: image.id === id }))

export const sortImagesByRank = memoize(images => sortBy(images, 'rank'))

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true, isLoadFailed: false },
      })

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          count: action.count,
          images: action.images,
        },
      })

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false, isLoadFailed: true },
      })

    case MOVE:
      return update(state, {
        images: {
          $set: recalculateImageRanks(
            arrayMove(state.images, action.fromIndex, action.toIndex),
          ),
        },
      })

    case START_SAVE_RANKS:
      return update(state, {
        $merge: { isSavingRanks: true, isSaveRanksFailed: false },
      })

    case COMPLETE_SAVE_RANKS:
      return update(state, { $merge: { isSavingRanks: false } })

    case FAIL_SAVE_RANKS:
      return update(state, { $merge: { isSavingRanks: false } })

    case START_SAVE_DESCRIPTION:
      return update(state, {
        $merge: { isSavingDescription: true, isSaveDescriptionFailed: false },
      })

    case COMPLETE_SAVE_DESCRIPTION: {
      const index = findIndex(state.images, { id: action.id })
      return update(state, {
        isSavingDescription: { $set: false },
        images: { [index]: { description: { $set: action.description } } },
      })
    }

    case FAIL_SAVE_DESCRIPTION:
      return update(state, { $merge: { isSavingDescription: false } })

    case START_SET_HERO:
      return update(state, {
        $merge: { isSettingHero: true, isSetHeroFailed: false },
      })

    case COMPLETE_SET_HERO: {
      return update(state, {
        images: {
          $set: setImageAsHero(state.images, action.id),
        },
      })
    }

    case FAIL_SET_HERO:
      return update(state, { $merge: { isSettingHero: false } })

    case START_UPLOAD:
      return update(state, {
        $merge: { isUploading: true, isUploadFailed: false },
      })

    case COMPLETE_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
        images: {
          $set: recalculateImageRanks([...state.images, ...action.images]),
        },
      })

    case FAIL_UPLOAD:
      return update(state, {
        $merge: { isUploading: false, isUploadFailed: true },
      })

    case START_DELETE:
      return update(state, {
        $merge: { isDeleting: true, isDeleteFailed: false },
      })

    case COMPLETE_DELETE: {
      const index = findIndex(state.images, { id: action.id })
      return update(state, {
        isDeleting: { $set: false },
        images: { $splice: [[index, 1]] },
      })
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: { isDeleting: false, isDeleteFailed: true },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startLoad = () => ({ type: START_LOAD })
const completeLoad = images => ({ type: COMPLETE_LOAD, images })
const failLoad = () => ({ type: FAIL_LOAD })
const startSaveRanks = () => ({ type: START_SAVE_RANKS })
const completeSaveRanks = () => ({ type: COMPLETE_SAVE_RANKS })
const failSaveRanks = () => ({ type: FAIL_SAVE_RANKS })
const startSaveDescription = () => ({ type: START_SAVE_DESCRIPTION })

const completeSaveDescription = (id, description) => ({
  type: COMPLETE_SAVE_DESCRIPTION,
  id,
  description,
})

const failSaveDescription = () => ({ type: FAIL_SAVE_DESCRIPTION })
const startSetHero = () => ({ type: START_SET_HERO })
const completeSetHero = id => ({ type: COMPLETE_SET_HERO, id })
const failSetHero = () => ({ type: FAIL_SET_HERO })
const startUpload = () => ({ type: START_UPLOAD })
const completeUpload = images => ({ type: COMPLETE_UPLOAD, images })
const failUpload = () => ({ type: FAIL_UPLOAD })
const startDelete = () => ({ type: START_DELETE })
const completeDelete = id => ({ type: COMPLETE_DELETE, id })
const failDelete = () => ({ type: FAIL_DELETE })

export const loadImages = providerId => dispatch => {
  dispatch(startLoad())
  const loadPromise = doLoadImages(providerId)

  loadPromise
    .then(images => dispatch(completeLoad(images)))
    .catch(() => dispatch(failLoad()))

  return loadPromise
}

export const moveImage = (fromIndex, toIndex) => ({
  type: MOVE,
  fromIndex,
  toIndex,
})

export const saveImageRanks = providerId => (dispatch, getState) => {
  dispatch(startSaveRanks())
  const state = getState()
  const { images } = state.providers.images
  const ranksById = mapValues(mapKeys(images, 'id'), 'rank')
  const savePromise = doSaveImageRanks(providerId, ranksById)

  savePromise
    .then(({ checksum }) => {
      dispatch(completeSaveRanks())
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failSaveRanks()))

  return savePromise
}

export const saveImageDescription = (
  id,
  providerId,
  description,
) => dispatch => {
  dispatch(startSaveDescription())
  const savePromise = doSaveImageDescription(id, providerId, description)

  savePromise
    .then(({ checksum }) => {
      dispatch(completeSaveDescription(id, description))
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failSaveDescription()))

  return savePromise
}

export const setHeroImage = (id, providerId) => dispatch => {
  dispatch(startSetHero())
  const setHeroPromise = doSetHeroImage(id, providerId)

  setHeroPromise
    .then(({ checksum }) => {
      dispatch(completeSetHero(id))
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failSetHero()))

  return setHeroPromise
}

export const uploadImages = (providerId, files) => dispatch => {
  dispatch(startUpload())
  const uploadPromise = doUploadImages(providerId, files)

  uploadPromise
    .then(({ images, checksum }) => {
      dispatch(completeUpload(images))
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failUpload()))

  return uploadPromise
}

export const deleteImage = (id, providerId) => dispatch => {
  dispatch(startDelete())
  const deletePromise = doDeleteImage(id, providerId)

  deletePromise
    .then(({ checksum }) => {
      dispatch(completeDelete(id))
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failDelete()))

  return deletePromise
}

export const resetImages = () => ({ type: RESET })
