import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Button, ButtonSet } from '../../../core/components/styled'
import { Input, ButtonLink } from '../../../core/components'
import { AccountPanelInfo } from '../styled'

const LoginForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field name="email" label="Email" component={Input} />
    <Field name="password" label="Password" type="password" component={Input} />

    <ButtonLink className="link" to="/account/password/forgot">
      Forgot your password?
    </ButtonLink>

    <ButtonSet margin="medium no small">
      <AccountPanelInfo>
        If you are a returning user, please login using your username and
        password.
      </AccountPanelInfo>

      <Button type="submit" fluid color="primary" margin="no no xSmall">
        Log In
      </Button>

      <AccountPanelInfo>
        If you do not have an account yet, please register first.
      </AccountPanelInfo>

      <Button
        type="button"
        as={ButtonLink}
        to="/account/register"
        fluid
        line
        color="primary"
      >
        Register
      </Button>
    </ButtonSet>
  </form>
)

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default LoginForm
