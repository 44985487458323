import React from 'react'
import { Route, Switch } from 'react-router'
import { AdminRoute, ProviderRoute } from '../../account/components/routes'
import {
  ProviderSearchPage,
  ProvidersPage,
  ProviderCreatorPage,
  ProviderEditorResolver,
  ProviderClaimPage,
} from './pages'

const ProvidersRouter = () => (
  <Switch>
    <AdminRoute exact path="/providers/search" component={ProviderSearchPage} />
    <ProviderRoute exact path="/providers" component={ProvidersPage} />

    <AdminRoute
      exact
      path="/providers/create"
      component={ProviderCreatorPage}
    />

    <Route
      exact
      path="/providers/:providerId"
      component={ProviderEditorResolver}
    />

    <Route
      exact
      path="/providers/:providerId/claim"
      component={ProviderClaimPage}
    />
  </Switch>
)

export default ProvidersRouter
