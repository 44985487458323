import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { removeNotification } from '../ducks'
import { NotificationSet as NotificationSetContainer } from './styled'
import Notification from './Notification'

const NotificationSet = ({ notifications, removeNotification }) => (
  <NotificationSetContainer>
    {notifications.map(({ id, message, color }) => (
      <Notification
        key={id}
        id={id}
        message={message}
        color={color}
        handleNotificationRemoval={removeNotification}
      />
    ))}
  </NotificationSetContainer>
)

NotificationSet.propTypes = {
  notifications: PropTypes.array.isRequired,
  removeNotification: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  notifications: state.core.notifications,
})

const mapDispatchToProps = { removeNotification }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationSet)
