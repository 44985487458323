import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  createSuccessNotification,
  createErrorNotification,
} from '../../../core/ducks'
import { Page } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { register, resetRegister } from '../../ducks'
import { registerSchema } from '../../schemas'
import { AccountPanel, AccountPanelTitle } from '../styled'
import { RegisterForm } from '../forms'

const RegisterPage = ({
  isRegistered,
  isRegistering,
  createSuccessNotification,
  createErrorNotification,
  register,
  resetRegister,
}) => {
  const handleSubmit = useCallback(
    data => {
      register(data)
        .then(() =>
          createSuccessNotification(
            "Your account was created. You will shortly receive an account verification link over email. If you're having trouble finding the email message, please also check your spam folder.",
            false,
          ),
        )
        .catch(() => createErrorNotification('Creating account failed.'))
    },
    [createSuccessNotification, createErrorNotification, register],
  )

  useEffect(() => resetRegister, [resetRegister])

  return (
    <>
      <DocumentTitle>Register</DocumentTitle>
      <Page>
        <AccountPanel isLoading={isRegistering}>
          <AccountPanelTitle>Register</AccountPanelTitle>

          <Formik
            render={RegisterForm}
            initialValues={{
              email: '',
              phoneNumber: '',
              firstName: '',
              lastName: '',
              state: '',
              city: '',
              address: '',
              zipCode: '',
              password: '',
              confirmedPassword: '',
            }}
            validationSchema={registerSchema}
            onSubmit={handleSubmit}
          />

          {isRegistered && <Redirect to={{ pathname: '/account/login' }} />}
        </AccountPanel>
      </Page>
    </>
  )
}

RegisterPage.propTypes = {
  isRegistered: PropTypes.bool.isRequired,
  isRegistering: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  resetRegister: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isRegistered: state.account.register.isRegistered,
  isRegistering: state.account.register.isRegistering,
})

const mapDispatchToProps = {
  createSuccessNotification,
  createErrorNotification,
  register,
  resetRegister,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterPage)
