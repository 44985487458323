import React from 'react'
import { Route, Switch } from 'react-router'
import { ClaimsPage, PublicationsPage } from './pages'

const ModerationRouter = () => (
  <Switch>
    <Route exact path="/moderation/claims" component={ClaimsPage} />
    <Route exact path="/moderation/publications" component={PublicationsPage} />
  </Switch>
)

export default ModerationRouter
