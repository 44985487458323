import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import {
  ButtonSet,
  Button,
  Grid,
  GridColumn,
} from '../../../core/components/styled'
import { ButtonLink, Input } from '../../../core/components'
import { AccountPanelInfo } from '../styled'
import { AccountEditorFields } from '../sections'

const RegisterForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Grid multiLine>
      <AccountEditorFields />

      <GridColumn size="6/12" padding="no small no no">
        <Field
          name="password"
          label="Password"
          type="password"
          component={Input}
        />
      </GridColumn>
      <GridColumn size="6/12" padding="no small no no">
        <Field
          name="confirmedPassword"
          label="Confirm password"
          type="password"
          component={Input}
        />
      </GridColumn>
    </Grid>

    <ButtonSet>
      <AccountPanelInfo>
        If you do not have an account yet, please register first.
      </AccountPanelInfo>

      <Button fluid color="primary" margin="no no xSmall" type="submit">
        Register
      </Button>

      <AccountPanelInfo>
        If you are a returning user, please login using your username and
        password.
      </AccountPanelInfo>

      <Button
        type="button"
        as={ButtonLink}
        to="/account/login"
        fluid
        line
        color="primary"
      >
        Log in
      </Button>
    </ButtonSet>
  </form>
)

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default RegisterForm
