import React from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router'
import { setForwardUrl, setProviderClaimUrl } from '../../services'

const AuthRoute = ({ isLoggedIn, location, component: Component, ...rest }) => {
  const renderComponent = props => {
    if (isLoggedIn) return <Component {...props} />
    setForwardUrl(`${location.pathname}${location.search}`)
    setProviderClaimUrl(location.pathname)
    return <Redirect to={{ pathname: '/account/login' }} />
  }

  return <Route {...rest} render={renderComponent} />
}

AuthRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
  location: RouterPropTypes.location.isRequired,
}

const mapStateToProps = state => ({
  isLoggedIn: state.account.login.isLoggedIn,
})

export default withRouter(connect(mapStateToProps)(AuthRoute))
