import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import RouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import {
  createSuccessNotification,
  createErrorNotification,
} from '../../../core/ducks'
import { PageLoading } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { claimProvider, resetProviderClaim } from '../../ducks'
import { removeProviderClaimUrl } from '../../../account/services/claim'

const ProviderClaimPage = ({
  createSuccessNotification,
  createErrorNotification,
  claimProvider,
  resetProviderClaim,
  push,
  match,
}) => {
  removeProviderClaimUrl()
  claimProvider(match.params.providerId)
    .then(() => {
      createSuccessNotification('The provider was claimed successfully.')
      push('/providers')
    })
    .catch(() => {
      createErrorNotification('Claiming provider failed.')
      push('/providers')
    })

  useEffect(() => resetProviderClaim, [resetProviderClaim])

  return (
    <>
      <DocumentTitle>Claiming property..</DocumentTitle>
      <PageLoading />
    </>
  )
}

ProviderClaimPage.propTypes = {
  createSuccessNotification: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  claimProvider: PropTypes.func.isRequired,
  resetProviderClaim: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  match: RouterPropTypes.match.isRequired,
}

const mapDispatchToProps = {
  createSuccessNotification,
  createErrorNotification,
  claimProvider,
  resetProviderClaim,
  push,
}

export default connect(
  undefined,
  mapDispatchToProps,
)(ProviderClaimPage)
