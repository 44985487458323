import { history, http } from '../../core/services'
import { getAccessToken } from './auth'

const requestInterceptor = config => {
  const accessToken = getAccessToken()

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
}
/* eslint-enable no-param-reassign */

const requestErrorInterceptor = error => Promise.reject(error)
const responseInterceptor = response => response

const responseErrorInterceptor = error => {
  if (error.response.status === 401) {
    history.push({
      pathname: '/account/logout',
      state: { from: history.location },
    })
  }

  return Promise.reject(error)
}

const registerAuthInterceptor = () => {
  http.interceptors.request.use(requestInterceptor, requestErrorInterceptor)
  http.interceptors.response.use(responseInterceptor, responseErrorInterceptor)
}

export default registerAuthInterceptor
