import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'

class Resolver extends PureComponent {
  state = { resolved: false }

  isComponentMounted = false

  componentDidMount() {
    this.isComponentMounted = true
    const { resolve, push, onSuccess, onError, redirectOnError } = this.props

    resolve()
      .then(result => {
        if (!this.isComponentMounted) return
        if (onSuccess) onSuccess(result)
        this.setState({ resolved: true })
      })
      .catch(error => {
        if (!this.isComponentMounted) return
        if (onError) onError(error)
        if (redirectOnError) push(redirectOnError)
      })
  }

  componentWillUnmount() {
    this.isComponentMounted = false
  }

  render() {
    const { resolved } = this.state
    const {
      successComponent: SuccessComponent,
      loadingComponent: LoadingComponent,
      successProps,
    } = this.props

    if (!resolved) return <LoadingComponent />
    return <SuccessComponent {...successProps} />
  }
}

Resolver.propTypes = {
  resolve: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  redirectOnError: PropTypes.string,
  successComponent: PropTypes.elementType.isRequired,
  loadingComponent: PropTypes.elementType.isRequired,
  successProps: PropTypes.object,
}

Resolver.defaultProps = {
  onSuccess: undefined,
  onError: undefined,
  redirectOnError: undefined,
  successProps: undefined,
}

const mapDispatchToProps = { push }

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(Resolver),
)
