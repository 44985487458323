import React from 'react'
import { Switch } from 'react-router'
import {
  RegisterPage,
  LoginPage,
  LogoutPage,
  VerificationPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ChangePasswordPage,
  AccountEditorResolver,
} from './pages'
import { GuestRoute, AuthRoute } from './routes'

const AccountRouter = () => (
  <Switch>
    <GuestRoute exact path="/account/register" component={RegisterPage} />
    <GuestRoute exact path="/account/login" component={LoginPage} />
    <GuestRoute
      exact
      path="/account/verification"
      component={VerificationPage}
    />
    <GuestRoute
      exact
      path="/account/password/forgot"
      component={ForgotPasswordPage}
    />
    <GuestRoute
      exact
      path="/account/password/reset"
      component={ResetPasswordPage}
    />
    <AuthRoute
      exact
      path="/account/password/change"
      component={ChangePasswordPage}
    />
    <AuthRoute exact path="/account/edit" component={AccountEditorResolver} />
    <AuthRoute exact path="/account/logout" component={LogoutPage} />
  </Switch>
)

export default AccountRouter
