import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTable, usePagination } from 'react-table'
import { useDidChange } from '../../../../core/hooks'
import {
  Panel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '../../../../core/components/styled'
import { TablePagination } from '../../../../core/components'
import { AdminGuard } from '../../../../account/components/guards'

const ProviderSearchTable = ({
  columns,
  filters,
  data,
  isLoading,
  pageCount: controlledPageCount,
  totalCount,
  fetchData,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
    pageSize,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
  )

  const didFiltersChange = useDidChange(filters)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!didFiltersChange || !pageIndex) {
      fetchData(filters, pageIndex + 1, pageSize)
    } else {
      gotoPage(0)
    }
  }, [filters, pageIndex, pageSize])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Panel isLoading={isLoading}>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {page.map(
            row =>
              prepareRow(row) || (
                <TableRow
                  {...row.getRowProps(
                    row.original.isLicenseExpired
                      ? { backgroundColor: '#ffe5e5' }
                      : {},
                  )}
                >
                  {row.cells.map(cell => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>

      <AdminGuard>
        <TablePagination
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          totalCount={totalCount}
        />
      </AdminGuard>
    </Panel>
  )
}

ProviderSearchTable.propTypes = {
  columns: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
}

export default ProviderSearchTable
