import * as Yup from 'yup'
import { VALIDATION_REGEXES } from '../../utils/constants'

const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please povide a valid email address.')
    .required('Please provide your email address.'),

  phoneNumber: Yup.string()
    .matches(
      VALIDATION_REGEXES.PHONE_NUMBER,
      'Please provide a valid phone number.',
    )
    .required('Please provide your phone number.'),

  firstName: Yup.string().required('Please provide your first name.'),
  lastName: Yup.string().required('Please provide your last name.'),
  state: Yup.string().required('Please provide your state.'),
  city: Yup.string().required('Please provide your city.'),
  address: Yup.string().required('Please provide your street address.'),
  zipCode: Yup.string().required('Please provide your zip code.'),

  password: Yup.string()
    .min(6, 'Password must be min 6 chars long.')
    .matches(
      VALIDATION_REGEXES.PASSWORD,
      'Password must contain at least one lowercase character, one uppercase character, one digit and one special character out of the following list #?!@$%^&*-.].',
    )
    .required('Please choose a password.'),

  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match.')
    .required('Please confirm your password.'),
})

export default registerSchema
