import { combineReducers } from 'redux'
import { reducer as notificationsReducer } from './notifications'

export {
  createSuccessNotification,
  createErrorNotification,
  removeNotification,
} from './notifications'

export const reducer = combineReducers({
  notifications: notificationsReducer,
})
