import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { triggerFlowAccountForgotPassword, resetAccount } from '../../ducks'
import { createSuccessNotification } from '../../../core/ducks'
import { Message } from '../../../core/components/styled'
import { Page } from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { forgotPasswordSchema } from '../../schemas'
import { AccountPanel, AccountPanelTitle } from '../styled'
import { ForgotPasswordForm } from '../forms'

const ForgotPasswordPage = ({
  isTriggeringForgotPassword,
  isTriggerForgotPassword,
  isTriggerForgotPasswordFailed,
  createSuccessNotification,
  triggerFlowAccountForgotPassword,
  resetAccount,
}) => {
  const handleSubmit = useCallback(
    ({ email }) => {
      triggerFlowAccountForgotPassword(email)
        .then(() =>
          createSuccessNotification(
            "You will shortly receive a passsword reset link over email. If you're having trouble finding the email message, please also check your spam folder.",
            false,
          ),
        )
        .catch(() => {})
    },
    [createSuccessNotification, triggerFlowAccountForgotPassword],
  )

  useEffect(() => resetAccount, [resetAccount])

  return (
    <>
      <DocumentTitle>Forgot your password?</DocumentTitle>
      <Page>
        <AccountPanel isLoading={isTriggeringForgotPassword}>
          <AccountPanelTitle>Forgot your password?</AccountPanelTitle>

          <Formik
            render={ForgotPasswordForm}
            initialValues={{ email: '' }}
            validationSchema={forgotPasswordSchema}
            onSubmit={handleSubmit}
          />

          {isTriggerForgotPasswordFailed && (
            <Message animated margin="medium no no" color="alert">
              Could not send request to reset your password.
            </Message>
          )}

          {isTriggerForgotPassword && <Redirect to={{ pathname: '/' }} />}
        </AccountPanel>
      </Page>
    </>
  )
}

ForgotPasswordPage.propTypes = {
  isTriggerForgotPassword: PropTypes.bool.isRequired,
  isTriggeringForgotPassword: PropTypes.bool.isRequired,
  isTriggerForgotPasswordFailed: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  triggerFlowAccountForgotPassword: PropTypes.func.isRequired,
  resetAccount: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isTriggerForgotPassword: state.account.account.isTriggerForgotPassword,
  isTriggeringForgotPassword: state.account.account.isTriggeringForgotPassword,
  isTriggerForgotPasswordFailed:
    state.account.account.isTriggerForgotPasswordFailed,
})

const mapDispatchToProps = {
  createSuccessNotification,
  triggerFlowAccountForgotPassword,
  resetAccount,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordPage)
