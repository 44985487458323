import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Formik } from 'formik'
import {
  createSuccessNotification,
  createErrorNotification,
} from '../../../core/ducks'
import { Page } from '../../../common/components'
import { updateAccount } from '../../ducks'
import { accountSchema } from '../../schemas'
import { AccountPanel, AccountPanelTitle } from '../styled'
import { AccountEditorForm } from '../forms'

const AccountEditorPage = ({
  account,
  isUpdatingAccount,
  createSuccessNotification,
  createErrorNotification,
  updateAccount,
  push,
}) => {
  const handleAccountEditorFormSubmit = useCallback(
    async formData => {
      try {
        await updateAccount(formData)
        createSuccessNotification('Your account was updated.')
        push('/providers')
      } catch (error) {
        createErrorNotification('Updating your account failed.')
      }
    },
    [createSuccessNotification, createErrorNotification, updateAccount, push],
  )

  return (
    <Page>
      <AccountPanel isLoading={isUpdatingAccount}>
        <AccountPanelTitle>Edit account</AccountPanelTitle>

        <Formik
          render={AccountEditorForm}
          initialValues={account}
          validationSchema={accountSchema}
          onSubmit={handleAccountEditorFormSubmit}
        />
      </AccountPanel>
    </Page>
  )
}

AccountEditorPage.propTypes = {
  account: PropTypes.object.isRequired,
  isUpdatingAccount: PropTypes.bool.isRequired,
  createSuccessNotification: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  account: state.account.account.account,
  isUpdatingAccount: state.account.account.isUpdatingAccount,
})

const mapDispatchToProps = {
  createSuccessNotification,
  createErrorNotification,
  updateAccount,
  push,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AccountEditorPage),
)
