import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { BareButton } from './Button'
import Icon from '../Icon'

export const ImageSetItemDeleteButtonIcon = styled(Icon).attrs({
  icon: 'delete',
})`
  width: 10px;
  height: 10px;
`

export const ImageSetItemDeleteButton = styled(BareButton).attrs({
  type: 'button',
})`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.grayBase};
  border-radius: 50%;
  color: #fff;
`

export const ImageSetItemOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const ImageSetItemSetHeroButtonIcon = styled(Icon).attrs({
  icon: 'star',
})`
  width: 12px;
  height: 12px;
`

export const ImageSetItemSetHeroButton = styled(BareButton).attrs({
  type: 'button',
})`
  position: absolute;
  top: 8px;
  right: 32px;
  color: #fff;

  ${props =>
    props.isSelected &&
    css`
      color: ${props => props.theme.brandWarning};
    `}
`

export const ImageSetItemDescriptionEditButtonIcon = styled(Icon).attrs({
  icon: 'edit',
})`
  width: 12px;
  height: 12px;
`

export const ImageSetItemDescriptionEditButton = styled(BareButton).attrs({
  type: 'button',
})`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const ImageSetItemDescription = styled.div`
  position: absolute;
  top: 155px;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 6px 32px 6px 8px;
  overflow: hidden;
  background-color: ${props => transparentize(0.6, props.theme.grayBase)};
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
`

export const ImageSetItem = styled.div`
  position: relative;
  width: 24%;
  margin: 0 1.33% 1.33% 0;
  overflow: hidden;
  padding-bottom: 16%;
  background-size: 100%;
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;

  &:nth-child(4n) {
    margin-right: 0;
  }
`

export const ImageSet = styled.div`
  display: flex;
  flex-wrap: wrap;
`
