import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelActions,
  PanelSection,
  Button,
} from '../../../core/components/styled'
import { Textarea } from '../../../core/components'

const ProviderNotesEditorForm = ({ isSavingNotes, dirty, handleSubmit }) => (
  <Panel padding="small" margin="no no medium">
    <form onSubmit={handleSubmit}>
      <PanelHeader>
        <PanelTitle>Notes</PanelTitle>
        <PanelActions>
          <Button type="submit" color="primary" disabled={!dirty}>
            {isSavingNotes ? 'Saving' : 'Save'}
          </Button>
        </PanelActions>
      </PanelHeader>

      <PanelSection>
        <Field name="notes" label="Notes" component={Textarea} />
      </PanelSection>
    </form>
  </Panel>
)

ProviderNotesEditorForm.propTypes = {
  isSavingNotes: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default ProviderNotesEditorForm
