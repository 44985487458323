import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Notification as NotificationContainer,
  NotificationClose,
  NotificationCloseIcon,
} from './styled'

const Notification = ({ id, message, color, handleNotificationRemoval }) => {
  const removeNotification = useCallback(() => handleNotificationRemoval(id), [
    id,
    handleNotificationRemoval,
  ])

  return (
    <NotificationContainer color={color}>
      <NotificationClose onClick={removeNotification}>
        <NotificationCloseIcon />
      </NotificationClose>
      {message}
    </NotificationContainer>
  )
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  handleNotificationRemoval: PropTypes.func.isRequired,
}

export default Notification
