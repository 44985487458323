import { http } from '../../core/services'
import transformUploadedImages from './transformUploadedImages'

export const loadImages = providerId =>
  http
    .get('management/images', { params: { providerId } })
    .then(response => response.data)

export const saveImageRanks = (providerId, ranksById) =>
  http
    .patch('management/images/ranks', ranksById, { params: { providerId } })
    .then(response => ({ checksum: response.headers['x-content-checksum'] }))

export const deleteImage = (id, providerId) =>
  http
    .delete(`management/images/${id}`, { params: { providerId } })
    .then(response => ({ checksum: response.headers['x-content-checksum'] }))

export const saveImageDescription = (id, providerId, description) =>
  http
    .patch(`management/images/${id}/description`, undefined, {
      params: { providerId, description },
    })
    .then(response => ({ checksum: response.headers['x-content-checksum'] }))

export const setHeroImage = (id, providerId) =>
  http
    .patch(`management/images/${id}/is-hero`, undefined, {
      params: { providerId },
    })
    .then(response => ({ checksum: response.headers['x-content-checksum'] }))

export const uploadImages = (providerId, files) => {
  const formData = new FormData()
  formData.append('providerId', providerId)
  files.forEach(file => formData.append('files', file))
  const headers = { 'Content-Type': 'multipart/form-data' }

  return http
    .post('management/images', formData, { headers })
    .then(response => ({
      images: transformUploadedImages(response.data),
      checksum: response.headers['x-content-checksum'],
    }))
}
