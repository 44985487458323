export const INDEPENDENT_LIVING = 'IndependentLiving'
export const ASSISTED_LIVING = 'AssistedLiving'
export const MEMORY_CARE = 'MemoryCare'
export const UNCATEGORIZED = 'Uncategorized'
export const HIDDEN = 'Hidden'

export const PROVIDER_CATEGORIES = {
  [INDEPENDENT_LIVING]: 'Independent Living',
  [ASSISTED_LIVING]: 'Assisted Living',
  [MEMORY_CARE]: 'Memory Care',
  [UNCATEGORIZED]: 'New',
  [HIDDEN]: 'Hidden',
}
