import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isAdmin } from '../../ducks'

const ProviderGuard = ({ isAdmin, children }) => (!isAdmin ? children : null)

ProviderGuard.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state.account.login.user),
})

export default connect(mapStateToProps)(ProviderGuard)
