import styled from 'styled-components'
import { transparentize } from 'polished'
import Icon from '../Icon'

export const TooltipDropdown = styled.div`
  display: none;
  position: absolute;
  top: 26px;
  left: -139px;
  z-index: 1;
  width: 300px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.brandPrimary};
  box-shadow: 0 2px 6px ${props => transparentize(0.9, props.theme.grayBase)};
  font-size: 13px;
`

export const TooltipIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`

export const Tooltip = styled.div`
  position: relative;
  width: 22px;
  height: 22px;

  &:hover {
    ${TooltipDropdown} {
      display: block;
    }
  }
`
