import { combineReducers } from 'redux'
import { reducer as registerReducer } from './register'
import { reducer as loginReducer } from './login'
import { reducer as accountReducer } from './account'

export { register, resetRegister } from './register'
export { login, logout, resetLogin, isAdmin } from './login'
export {
  verifyAccount,
  triggerFlowAccountForgotPassword,
  resetAccountPassword,
  changeAccountPassword,
  loadAccount,
  updateAccount,
  resetAccount,
} from './account'

export const reducer = combineReducers({
  register: registerReducer,
  login: loginReducer,
  account: accountReducer,
})
