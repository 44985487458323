import React from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-autosize-textarea'
import { FormGroup, FormLabel, FormError, Input as FormInput } from './styled'

const Textarea = ({
  field,
  form: { touched, errors },
  label,
  placeholder,
  margin,
  ...props
}) => (
  <FormGroup hasValue={!!field.value} margin={margin}>
    {!!label && <FormLabel>{label}</FormLabel>}
    <FormInput
      as={TextareaAutosize}
      {...field}
      {...props}
      placeholder={placeholder}
    />

    {touched[field.name] && errors[field.name] && (
      <FormError>{errors[field.name]}</FormError>
    )}
  </FormGroup>
)

Textarea.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
}

Textarea.defaultProps = {
  placeholder: undefined,
  label: undefined,
  margin: undefined,
}

export default Textarea
