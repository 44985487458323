import React from 'react'
import PropTypes from 'prop-types'
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelSection,
  PanelSectionTitle,
} from '../../../../core/components/styled'
import { Dropzone } from '../../../../core/components'
import ImageSet from './ImageSet'

const ImagesEditor = ({
  images,
  isUploadingImages,
  handleImageReorder,
  handleImageDescriptionChange,
  handleImageIsHeroChange,
  handleImageUpload,
  handleImageDeletion,
}) => (
  <Panel margin="no no small" isLoading={isUploadingImages}>
    <PanelHeader>
      <PanelTitle>Images</PanelTitle>
    </PanelHeader>

    <PanelSection>
      {!!images.length && (
        <ImageSet
          axis="xy"
          images={images}
          onSortEnd={handleImageReorder}
          handleImageDescriptionChange={handleImageDescriptionChange}
          handleImageIsHeroChange={handleImageIsHeroChange}
          handleImageDeletion={handleImageDeletion}
          useDragHandle
        />
      )}

      <Dropzone
        description="Drop photos here or click to upload."
        onFilesSelected={handleImageUpload}
      />
    </PanelSection>
    <PanelSection>
      <PanelSectionTitle>Tips</PanelSectionTitle>
      <p>
        One highly recommended action you should consider is having professional
        HD photos taken to showcase your community. You may use any service of
        your choosing to take your photos, however, we have worked out special
        pricing with Rocket Lister{' '}
        <b>
          <a
            style={{ textDecoration: 'underline' }}
            target="blank"
            href="https://rocketlister.com/loved-ones-first/"
          >
            {' '}
            by following this link.
          </a>
        </b>
      </p>
      <p>
        Rocket Lister will forward the photos directly to you and our marketing
        team, usually within 24 hours. Once you receive your photos, pick out
        the ones you feel represent your community the best and upload to your
        Loved Ones First profile. Alternatively, you can direct us to have our
        marketing team upload the photos on your behalf. Consider this a small
        investment into your business and a sure way to get noticed.
      </p>
      <p>
        Disclaimer: Rocket Lister is a 3rd party service in which we have no
        ownership interests, and are in no way compensated for the services they
        provide to you. This service is provided as an added value to
        communities that wish to showcase themselves in the best light possible
        on Loved Ones First.
      </p>
    </PanelSection>
  </Panel>
)

ImagesEditor.propTypes = {
  images: PropTypes.array.isRequired,
  isUploadingImages: PropTypes.bool.isRequired,
  handleImageReorder: PropTypes.func.isRequired,
  handleImageDescriptionChange: PropTypes.func.isRequired,
  handleImageIsHeroChange: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  handleImageDeletion: PropTypes.func.isRequired,
}

export default ImagesEditor
