import { combineReducers } from 'redux'
import { reducer as providerSearchReducer } from './providerSearch'
import { reducer as providersReducer } from './providers'
import { reducer as providerReducer } from './provider'
import { reducer as amenitiesReducer } from './amenities'
import { reducer as imagesReducer } from './images'
import { reducer as notesReducer } from './notes'
import { reducer as providerClaimReducer } from './providerClaim'

export { searchProviders, resetProviderSearch } from './providerSearch'
export { loadProviders, resetProviders } from './providers'
export {
  loadProvider,
  loadProviderChecksum,
  saveProvider,
  publishProvider,
  resetProvider,
  hasUnpublishedChanges,
} from './provider'
export { loadAmenities, saveAmenities, resetAmenities } from './amenities'
export {
  loadImages,
  moveImage,
  saveImageRanks,
  saveImageDescription,
  setHeroImage,
  uploadImages,
  deleteImage,
  resetImages,
  sortImagesByRank,
} from './images'
export { loadNotes, saveNotes, resetNotes } from './notes'
export { claimProvider, resetProviderClaim } from './providerClaim'

export const reducer = combineReducers({
  providerSearch: providerSearchReducer,
  providers: providersReducer,
  provider: providerReducer,
  amenities: amenitiesReducer,
  images: imagesReducer,
  notes: notesReducer,
  providerClaim: providerClaimReducer,
})
