import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { sizeMapper, colorMapper } from '../../../utils/styles'

export const BareButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: inherit;
  user-select: none;
  cursor: pointer;
`

export const Button = styled(BareButton)`
  ${BareButton};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: 9px 25px;
  background-color: ${colorMapper};
  border-radius: 5px;
  text-transform: capitalize;
  line-height: 18px;
  font-size: 14px;
  color: #fff;

  &:active {
    background-color: ${props => darken(0.05, colorMapper(props))};
  }

  &:disabled {
    opacity: 0.5;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  ${props =>
    props.fluid &&
    css`
      width: 100%;
    `};

  ${props =>
    props.line &&
    css`
      background-color: transparent;
      border: 1px solid ${colorMapper(props)};
      color: ${colorMapper(props)};

      &:active {
        background-color: transparent;
        border-color: ${props => darken(0.05, colorMapper(props))};
        color: ${props => darken(0.05, colorMapper(props))};
      }
    `}
`
