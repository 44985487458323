import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import {
  Panel,
  PanelHeader,
  PanelFooter,
  PanelTitle,
  PanelActions,
  PanelSection,
  Columns,
  ColumnsContent,
  FormSection,
  FormSectionTitle,
  Button,
} from '../../../core/components/styled'
import { Checkbox } from '../../../core/components'

const AmenitiesEditorForm = ({
  amenities,
  isSavingAmenities,
  dirty,
  handleSubmit,
}) => (
  <Panel margin="no no small" style={dirty ? { background: '#FFFFF5' } : {}}>
    <form onSubmit={handleSubmit}>
      <PanelHeader>
        <PanelTitle>Amenities</PanelTitle>
        <PanelActions>
          <Button type="submit" color="primary" disabled={!dirty}>
            {isSavingAmenities ? 'Saving' : 'Save'}
          </Button>
        </PanelActions>
      </PanelHeader>

      <PanelSection>
        <Columns columnCount="2">
          {amenities.map(amenityGroup => (
            <ColumnsContent key={amenityGroup.name}>
              <FormSection>
                <FormSectionTitle>{amenityGroup.name}</FormSectionTitle>
                {amenityGroup.amenities.map(amenity => (
                  <Field
                    key={amenity.id}
                    name={`statuses.${amenity.id}`}
                    label={amenity.name}
                    component={Checkbox}
                    margin="no no small"
                    block
                  />
                ))}
              </FormSection>
            </ColumnsContent>
          ))}
        </Columns>
      </PanelSection>
      <PanelFooter>
        <PanelActions>
          <Button type="submit" color="primary" disabled={!dirty}>
            {isSavingAmenities ? 'Saving' : 'Save'}
          </Button>
        </PanelActions>
      </PanelFooter>
    </form>
  </Panel>
)

AmenitiesEditorForm.propTypes = {
  amenities: PropTypes.array.isRequired,
  isSavingAmenities: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default AmenitiesEditorForm
