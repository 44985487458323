import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createErrorNotification } from '../../../core/ducks'
import {
  PageContent,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { loadClaims, approveClaim, resetClaims } from '../../ducks'
import { ClaimsTable, ClaimsTableActions } from './sections'

const getColumns = handleClaimApproval => [
  { Header: '#', accessor: 'id' },
  { Header: 'Name', accessor: 'providerName' },
  { Header: 'Address', accessor: 'providerAddress.formattedAddress' },
  { Header: 'Phone', accessor: 'providerClaimer.phoneNumber' },
  { Header: 'Username', accessor: 'providerClaimer.userName' },
  {
    Header: 'Actions',
    Cell: props => (
      <ClaimsTableActions
        handleClaimApproval={handleClaimApproval}
        {...props}
      />
    ),
  },
]

const ClaimsPage = ({
  claims,
  isLoading,
  createErrorNotification,
  loadClaims,
  approveClaim,
  resetClaims,
}) => {
  const handleClaimApproval = useCallback(
    id =>
      window.confirm('Are you sure?') &&
      approveClaim(id).catch(() =>
        createErrorNotification('Claim approval failed.'),
      ),
    [approveClaim, createErrorNotification],
  )

  const columns = useMemo(() => getColumns(handleClaimApproval), [
    handleClaimApproval,
  ])

  useEffect(() => resetClaims, [resetClaims])

  return (
    <>
      <DocumentTitle>Claims</DocumentTitle>
      <PageContent>
        <PageHeader>
          <PageTitle>Claims</PageTitle>
        </PageHeader>

        <ClaimsTable
          columns={columns}
          data={claims}
          fetchData={loadClaims}
          isLoading={isLoading}
        />
      </PageContent>
    </>
  )
}

ClaimsPage.propTypes = {
  claims: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  loadClaims: PropTypes.func.isRequired,
  approveClaim: PropTypes.func.isRequired,
  resetClaims: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  claims: state.moderation.claims.claims,
  isLoading: state.moderation.claims.isLoading,
})

const mapDispatchToProps = {
  createErrorNotification,
  loadClaims,
  approveClaim,
  resetClaims,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimsPage)
