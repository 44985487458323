import save from 'immutability-helper'
import { loadNotes as doLoadNotes, saveNotes as doSaveNotes } from '../services'

// Actions
const START_LOAD = 'providers/notes/START_LOAD'
const COMPLETE_LOAD = 'providers/notes/COMPLETE_LOAD'
const FAIL_LOAD = 'providers/notes/FAIL_LOAD'
const START_SAVE = 'providers/notes/START_SAVE'
const COMPLETE_SAVE = 'providers/notes/COMPLETE_SAVE'
const FAIL_SAVE = 'providers/notes/FAIL_SAVE'
const RESET = 'providers/notes/RESET'

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  isSaving: false,
  isSaveFailed: false,
  notes: '',
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return save(state, {
        $merge: { isLoading: true, isLoadFailed: false },
      })

    case COMPLETE_LOAD:
      return save(state, {
        $merge: {
          isLoading: false,
          notes: action.notes,
        },
      })

    case FAIL_LOAD:
      return save(state, {
        $merge: { isLoading: false, isLoadFailed: true },
      })

    case START_SAVE:
      return save(state, {
        $merge: { isSaving: true, isSaveFailed: false },
      })

    case COMPLETE_SAVE:
      return save(state, { $merge: { isSaving: false } })

    case FAIL_SAVE:
      return save(state, {
        $merge: { isSaving: false },
      })

    case RESET:
      return save(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startLoad = () => ({ type: START_LOAD })
const completeLoad = notes => ({ type: COMPLETE_LOAD, notes })
const failLoad = () => ({ type: FAIL_LOAD })
const startSave = () => ({ type: START_SAVE })
const completeSave = () => ({ type: COMPLETE_SAVE })
const failSave = () => ({ type: FAIL_SAVE })

export const loadNotes = providerId => dispatch => {
  dispatch(startLoad())
  const loadPromise = doLoadNotes(providerId)

  loadPromise
    .then(notes => dispatch(completeLoad(notes)))
    .catch(() => dispatch(failLoad()))

  return loadPromise
}

export const saveNotes = (providerId, notes) => dispatch => {
  dispatch(startSave())
  const savePromise = doSaveNotes(providerId, notes)

  savePromise
    .then(() => dispatch(completeSave()))
    .catch(() => dispatch(failSave()))

  return savePromise
}

export const resetNotes = () => ({ type: RESET })
