import update from 'immutability-helper'
import { searchProviders as doSearchProviders } from '../services'

// Actions
const START_SEARCH = 'providers/search/START_SEARCH'
const COMPLETE_SEARCH = 'providers/search/COMPLETE_SEARCH'
const FAIL_SEARCH = 'providers/search/FAIL_SEARCH'
const RESET = 'providers/search/RESET'

// Initial state
const initialState = {
  isSearching: false,
  isSearchFailed: false,
  count: 0,
  pageCount: 0,
  providers: [],
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SEARCH:
      return update(state, {
        $merge: { isSearching: true, isSearchFailed: false },
      })

    case COMPLETE_SEARCH:
      return update(state, {
        $merge: {
          isSearching: false,
          count: action.count,
          pageCount: action.pageCount,
          providers: action.providers,
        },
      })

    case FAIL_SEARCH:
      return update(state, {
        $merge: { isSearching: false, isSearchFailed: true },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startSearch = () => ({ type: START_SEARCH })

const completeSearch = (count, pageCount, providers) => ({
  type: COMPLETE_SEARCH,
  count,
  pageCount,
  providers,
})

const failSearch = () => ({ type: FAIL_SEARCH })

export const searchProviders = (filters, offset, limit) => dispatch => {
  dispatch(startSearch())
  const loadPromise = doSearchProviders(filters, offset, limit)

  loadPromise
    .then(({ count, pageCount, providers }) => {
      dispatch(completeSearch(count, pageCount, providers))
    })
    .catch(() => dispatch(failSearch()))

  return loadPromise
}

export const resetProviderSearch = () => ({ type: RESET })
