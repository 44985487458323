import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash-es'
import { Field } from 'formik'
import { MultiSelect } from '../../../../core/components'
import { PROVIDER_CATEGORIES } from '../../../constants'

const CategoryMultiSelect = ({ name, ...props }) => {
  const options = useMemo(
    () => map(PROVIDER_CATEGORIES, (label, value) => ({ label, value })),
    [],
  )

  return (
    <Field
      name={name}
      label="Category"
      options={options}
      component={MultiSelect}
      {...props}
    />
  )
}

CategoryMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
}

export default CategoryMultiSelect
