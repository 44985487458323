import update from 'immutability-helper'
import memoize from 'memoize-one'
import {
  loadProvider as doLoadProvider,
  loadProviderChecksum as doLoadProviderChecksum,
  createProvider as doCreateProvider,
  updateProvider as doUpdateProvider,
  publishProvider as doPublishProvider,
} from '../services'

// Actions
const START_LOAD = 'providers/provider/START_LOAD'
const COMPLETE_LOAD = 'providers/provider/COMPLETE_LOAD'
const FAIL_LOAD = 'providers/provider/FAIL_LOAD'
const START_LOAD_CHECKSUM = 'providers/provider/START_LOAD_CHECKSUM'
const COMPLETE_LOAD_CHECKSUM = 'providers/provider/COMPLETE_LOAD_CHECKSUM'
const FAIL_LOAD_CHECKSUM = 'providers/provider/FAIL_LOAD_CHECKSUM'
const UPDATE_CURRENT_CHECKSUM = 'providers/provider/UPDATE_CURRENT_CHECKSUM'
const START_SAVE = 'providers/provider/START_SAVE'
const COMPLETE_SAVE = 'providers/provider/COMPLETE_SAVE'
const FAIL_SAVE = 'providers/provider/FAIL_SAVE'
const START_PUBLISH = 'providers/provider/START_PUBLISH'
const COMPLETE_PUBLISH = 'providers/provider/COMPLETE_PUBLISH'
const FAIL_PUBLISH = 'providers/provider/FAIL_PUBLISH'
const RESET = 'providers/provider/RESET'

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  isLoadingChecksum: false,
  isLoadChecksumFailed: false,
  isSaving: false,
  isSaveFailed: false,
  isPublishing: false,
  isPublishFailed: false,
  checksum: { current: undefined, published: undefined },
  provider: undefined,
}

// Helpers
export const hasUnpublishedChanges = memoize(
  checksum => checksum.current !== checksum.published,
)

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true, isLoadFailed: false },
      })

    case COMPLETE_LOAD:
      return update(state, {
        $merge: { isLoading: false, provider: action.provider },
      })

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false, isLoadFailed: true },
      })

    case START_LOAD_CHECKSUM:
      return update(state, {
        $merge: { isLoadingChecksum: true, isLoadChecksumFailed: false },
      })

    case COMPLETE_LOAD_CHECKSUM:
      return update(state, {
        $merge: { isLoadingChecksum: false, checksum: action.checksum },
      })

    case FAIL_LOAD_CHECKSUM:
      return update(state, {
        $merge: { isLoadingChecksum: false, isLoadChecksumFailed: true },
      })

    case UPDATE_CURRENT_CHECKSUM:
      return update(state, {
        checksum: { current: { $set: action.checksum } },
      })

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true, isSaveFailed: false },
      })

    case COMPLETE_SAVE:
      return update(state, { $merge: { isSaving: false } })

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false, isSaveFailed: true },
      })

    case START_PUBLISH:
      return update(state, {
        $merge: { isPublishing: true, isPublishFailed: false },
      })

    case COMPLETE_PUBLISH:
      return update(state, {
        $merge: { isPublishing: false, checksum: action.checksum },
      })

    case FAIL_PUBLISH:
      return update(state, {
        $merge: { isPublishing: false, isPublishFailed: true },
      })

    case RESET:
      return update(state, { $merge: initialState })

    default:
      return state
  }
}

// Action creators
const startLoad = () => ({ type: START_LOAD })
const completeLoad = provider => ({ type: COMPLETE_LOAD, provider })
const failLoad = () => ({ type: FAIL_LOAD })
const startLoadChecksum = () => ({ type: START_LOAD_CHECKSUM })
const completeLoadChecksum = checksum => ({
  type: COMPLETE_LOAD_CHECKSUM,
  checksum,
})
const failLoadChecksum = () => ({ type: FAIL_LOAD_CHECKSUM })
const startSave = () => ({ type: START_SAVE })
const completeSave = () => ({ type: COMPLETE_SAVE })
const failSave = () => ({ type: FAIL_SAVE })
const startPublish = () => ({ type: START_PUBLISH })
const completePublish = checksum => ({ type: COMPLETE_PUBLISH, checksum })
const failPublish = () => ({ type: FAIL_PUBLISH })

export const updateCurrentProviderChecksum = checksum => ({
  type: UPDATE_CURRENT_CHECKSUM,
  checksum,
})

export const loadProvider = id => dispatch => {
  dispatch(startLoad())
  const loadPromise = doLoadProvider(id)

  loadPromise
    .then(provider => dispatch(completeLoad(provider)))
    .catch(() => dispatch(failLoad()))

  return loadPromise
}

export const loadProviderChecksum = id => dispatch => {
  dispatch(startLoadChecksum())
  const loadPromise = doLoadProviderChecksum(id)

  loadPromise
    .then(checksum =>
      dispatch(
        completeLoadChecksum({
          current: checksum.currentContentChecksum,
          published: checksum.publishedContentChecksum,
          existsUnapprovedPublishedContent:
            checksum.existsUnapprovedPublishedContent,
        }),
      ),
    )
    .catch(() => dispatch(failLoadChecksum()))

  return loadPromise
}

export const saveProvider = (id, data) => dispatch => {
  dispatch(startSave())
  const savePromise = id ? doUpdateProvider(id, data) : doCreateProvider(data)

  savePromise
    .then(({ checksum }) => {
      dispatch(completeSave())
      dispatch(updateCurrentProviderChecksum(checksum))
    })
    .catch(() => dispatch(failSave()))

  return savePromise
}

export const publishProvider = id => dispatch => {
  dispatch(startPublish())
  const publishPromise = doPublishProvider(id)

  publishPromise
    .then(checksum =>
      dispatch(
        completePublish({
          current: checksum.currentContentChecksum,
          published: checksum.publishedContentChecksum,
          existsUnapprovedPublishedContent:
            checksum.existsUnapprovedPublishedContent,
        }),
      ),
    )
    .catch(() => dispatch(failPublish()))

  return publishPromise
}

export const resetProvider = () => ({ type: RESET })
