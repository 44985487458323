import styled from 'styled-components'
import { sizeMapper } from '../../../utils/styles'

export const ColumnsContent = styled.div`
  break-inside: avoid;
`

export const Columns = styled.div`
  column-count: ${props => props.columnCount};
  column-gap: ${props => sizeMapper(props.columnGap, 'medium')};
`
