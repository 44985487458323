import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router'
import {
  getForwardUrl,
  getProviderClaimUrl,
  removeForwardUrl,
} from '../../services'

const GuestRoute = ({ isLoggedIn, component: Component, ...rest }) => {
  const renderComponent = props => {
    if (!isLoggedIn) return <Component {...props} />
    const redirectTo = getProviderClaimUrl() || getForwardUrl() || '/'
    removeForwardUrl()

    return <Redirect to={redirectTo} />
  }

  return <Route {...rest} render={renderComponent} />
}

GuestRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
}

const mapStateToProps = state => ({
  isLoggedIn: state.account.login.isLoggedIn,
})

export default withRouter(connect(mapStateToProps)(GuestRoute))
