import { LOCAL, DEVELOPMENT, PRODUCTION } from './environments'

export const BASE_URL_MAP = {
  'backoffice.lovedonesfirst.com': PRODUCTION,
  'lof-bo-dev.azurewebsites.net': DEVELOPMENT,
}

export const API_BASE_URL_MAP = {
  [PRODUCTION]: 'https://betadevapi.azurewebsites.net/api',
  [DEVELOPMENT]: 'https://lof-api-dev.azurewebsites.net/api',
  [LOCAL]: 'https://localhost:5001/api',
}

export const FRONTEND_BASE_URL_MAP = {
  [PRODUCTION]: 'https://lovedonesfirst.com',
  [DEVELOPMENT]: 'https://lof-fe-dev.azurewebsites.net',
  [LOCAL]: 'http://localhost:3001',
}
