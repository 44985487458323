import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import {
  ImageSetItem as ImageSetItemContainer,
  ImageSetItemDescription,
  ImageSetItemDescriptionEditButton,
  ImageSetItemDescriptionEditButtonIcon,
  ImageSetItemSetHeroButton,
  ImageSetItemSetHeroButtonIcon,
  ImageSetItemDeleteButton,
  ImageSetItemDeleteButtonIcon,
} from '../../../../core/components/styled'
import ImageSetItemDragHandle from './ImageSetItemDragHandle'

const ImageSetItem = ({
  index,
  image: { id, description, isHero, urls },
  handleImageDescriptionChange,
  handleImageIsHeroChange,
  handleImageDeletion,
}) => {
  const saveDescription = useCallback(() => {
    const newDescription = window.prompt('Image description', description || '')
    handleImageDescriptionChange(id, newDescription)
  }, [id, description, handleImageDescriptionChange])

  const setHero = useCallback(() => {
    handleImageIsHeroChange(id)
  }, [id, handleImageIsHeroChange])

  const remove = useCallback(() => {
    handleImageDeletion(id)
  }, [id, handleImageDeletion])

  return (
    <ImageSetItemContainer src={urls.thumbnailSizeUrl}>
      <ImageSetItemDragHandle />

      <ImageSetItemDescription>
        {description || `${index + 1}.`}
        <ImageSetItemSetHeroButton isSelected={isHero} onClick={setHero}>
          <ImageSetItemSetHeroButtonIcon />
        </ImageSetItemSetHeroButton>

        <ImageSetItemDescriptionEditButton onClick={saveDescription}>
          <ImageSetItemDescriptionEditButtonIcon />
        </ImageSetItemDescriptionEditButton>
      </ImageSetItemDescription>

      <ImageSetItemDeleteButton onClick={remove}>
        <ImageSetItemDeleteButtonIcon />
      </ImageSetItemDeleteButton>
    </ImageSetItemContainer>
  )
}

ImageSetItem.propTypes = {
  index: PropTypes.number.isRequired,
  image: PropTypes.object.isRequired,
  handleImageDescriptionChange: PropTypes.func.isRequired,
  handleImageIsHeroChange: PropTypes.func.isRequired,
  handleImageDeletion: PropTypes.func.isRequired,
}

export default SortableElement(ImageSetItem)
