import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
} from './styled'

const Checkbox = ({ field, label, disabled, block, size, margin }) => (
  <CheckboxContainer
    isDisabled={disabled}
    block={block}
    size={size}
    margin={margin}
  >
    <CheckboxInput
      {...field}
      type="checkbox"
      disabled={disabled}
      checked={field.value}
    />
    <CheckboxCheck />
    <CheckboxText>{label}</CheckboxText>
  </CheckboxContainer>
)

Checkbox.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  margin: PropTypes.string,
}

Checkbox.defaultProps = {
  disabled: false,
  block: false,
  size: undefined,
  margin: undefined,
}

export default Checkbox
