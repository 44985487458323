import React from 'react'
import { Field } from 'formik'
import { GridColumn } from '../../../core/components/styled'
import { Input, StateSelect } from '../../../core/components'

const AccountEditorFields = () => (
  <>
    <GridColumn size="6/12" padding="no small no no">
      <Field name="email" label="Email" component={Input} />
    </GridColumn>
    <GridColumn size="6/12" padding="no small no no">
      <Field name="phoneNumber" label="Phone number" component={Input} />
    </GridColumn>

    <GridColumn size="6/12" padding="no small no no">
      <Field name="firstName" label="First name" component={Input} />
    </GridColumn>
    <GridColumn size="6/12" padding="no small no no">
      <Field name="lastName" label="Last name" component={Input} />
    </GridColumn>

    <GridColumn size="12/12" padding="no small no no">
      <Field name="address" label="Address" component={Input} />
    </GridColumn>

    <GridColumn size="12/12" padding="no small no no">
      <Field name="city" label="City" component={Input} />
    </GridColumn>
    <GridColumn size="6/12" padding="no small no no">
      <StateSelect name="state" />
    </GridColumn>
    <GridColumn size="6/12" padding="no small no no">
      <Field name="zipCode" label="Zip code" component={Input} />
    </GridColumn>
  </>
)

export default AccountEditorFields
