import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormLabel, FormError, Select as FormSelect } from './styled'

const Select = ({
  field,
  form: { touched, errors },
  margin,
  label,
  options,
  children,
  ...props
}) => (
  <FormGroup hasValue={!!field.value} margin={margin}>
    {!!label && <FormLabel>{label}</FormLabel>}

    <FormSelect notSelected={!field.value} {...field} {...props}>
      {children}

      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </FormSelect>

    {touched[field.name] && errors[field.name] && (
      <FormError>{errors[field.name]}</FormError>
    )}
  </FormGroup>
)

Select.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  label: PropTypes.string,
  options: PropTypes.array,
  margin: PropTypes.string,
}

Select.defaultProps = {
  options: [],
  children: undefined,
  label: undefined,
  margin: undefined,
}

export default Select
