import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  PageContent,
  PageHeader,
  PageHeaderActions,
  PageTitle,
} from '../../../common/components/styled'
import { DocumentTitle } from '../../../common/components'
import { loadProviders, resetProviders } from '../../ducks'
import { Button } from '../../../core/components/styled'
import {
  ProvidersTable,
  ProvidersTableStatus,
  ProvidersTableActions,
} from './sections'
import { getFrontendBaseURL } from '../../../core/services/environment'

const getColumns = () => [
  { Header: '#', accessor: 'providerId' },
  { Header: 'Name', accessor: 'providerName' },
  { Header: 'Address', accessor: 'providerAddress.formattedAddress' },
  { Header: 'Phone', accessor: 'providerClaimer.phoneNumber' },
  { Header: 'Status', Cell: ProvidersTableStatus },
  { Header: 'Actions', Cell: ProvidersTableActions },
]

const ProvidersPage = ({
  providers,
  isLoading,
  loadProviders,
  resetProviders,
}) => {
  const columns = useMemo(getColumns, [])

  useEffect(() => resetProviders, [resetProviders])

  return (
    <>
      <DocumentTitle>Providers</DocumentTitle>
      <PageContent>
        <PageHeader>
          <PageTitle>Providers</PageTitle>
          <PageHeaderActions>
            <Button
              as="a"
              href={`${getFrontendBaseURL()}/owners`}
              target="blank"
              color="primary"
              line
            >
              Claim another community
            </Button>
          </PageHeaderActions>
        </PageHeader>

        <ProvidersTable
          columns={columns}
          data={providers}
          fetchData={loadProviders}
          isLoading={isLoading}
        />
      </PageContent>
    </>
  )
}

ProvidersPage.propTypes = {
  providers: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  providers: state.providers.providers.providers,
  isLoading: state.providers.providers.isLoading,
})

const mapDispatchToProps = { loadProviders, resetProviders }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProvidersPage)
