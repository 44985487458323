import styled from 'styled-components'

export const FormSectionTitle = styled.span`
  display: block;
  margin-bottom: 20px;
  padding: 8px 0;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 13px;
`

export const FormSection = styled.div`
  margin-bottom: 30px;
`
